import { Box } from '@chakra-ui/react'
import React, { FunctionComponent, ReactNode } from 'react'

interface ConditionalRenderingLoggedInProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'ConditionalRenderingLoggedIn'

const styles = {
  wrapper: {
    display: 'contents',
    sx: {
      '[data-is-logged="false"] &': {
        display: 'none',
      },
    },
  },
}

/**
 * This is something different than GuardNotAuthorized wrapper.
 * This component will render children ALWAYS, but will hide them if user is logged in.
 *
 * Why like that? Because you can render something for (not) logged in users even though
 * you don't have access to the currently logged in user. SSR rendering is more performant
 * than rendering that on client side.
 *
 * @example
 * ```tsx
 * // We are on public accessible route.
 * <Link href="/my/orders/">
 *   <ConditionalRenderingNotLoggedIn>
 *     Login to see your orders
 *   </ConditionalRenderingNotLoggedIn>
 *   <ConditionalRenderingLoggedIn>
 *     View my orders
 *   </ConditionalRenderingLoggedIn>
 * </Link>
 * // Will display "View my orders" link if user is logged in.
 * // Will display "Login to see your orders" link if user is not logged in.
 * ```
 */
const ConditionalRenderingLoggedIn: FunctionComponent<ConditionalRenderingLoggedInProps> = ({ children }) => (
  <Box {...styles.wrapper}>{children}</Box>
)

ConditionalRenderingLoggedIn.displayName = TAG

export default ConditionalRenderingLoggedIn
