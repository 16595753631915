import { Stack, SystemStyleInterpolation, List, ListItem } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import CategoryItem from 'components/modules/Header/Navigation/CategoryItem'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface MobileSitesMenuProps {
  onSelect: () => void
}

const TAG = 'MobileCategoriesMenu'

const styles = {
  link: { fontSize: 'lg', fontWeight: 'bold', color: 'primary.red', px: 4, pt: 3 },
  list: { pb: 10 },
  listItem: { px: 4 },
} as const satisfies SystemStyleInterpolation

const MobileCategoriesMenu: FunctionComponent<MobileSitesMenuProps> = ({ onSelect }) => {
  const { t } = useTranslation()

  const { categories } = useBusinessUnit()

  return (
    <Stack as={List} spacing={5} {...styles.list}>
      <ListItem {...styles.link}>
        <Link href={RoutePaths.connectedOverview} variant="ternary" onClick={onSelect}>
          {t('components.header.connected.title')}
        </Link>
      </ListItem>
      {categories?.map((category) => (
        <ListItem key={category.id}>
          <CategoryItem onClick={onSelect} categoryId={category.id} key={category.id} {...styles.listItem} />
        </ListItem>
      ))}
    </Stack>
  )
}

MobileCategoriesMenu.displayName = TAG

export default MobileCategoriesMenu
