import type { ThemeComponents } from '@chakra-ui/react'

export const Radio = {
  variants: {
    primary: {
      control: {
        bg: 'white',
        borderColor: 'grayCCC',
        borderWidth: '1px',
        _checked: {
          borderColor: 'grayCCC',
          _hover: {
            bg: 'transparent',
          },
          _focus: {
            boxShadow: 'none',
            bg: 'white',
          },
          _focusVisible: {
            bg: 'white',
          },
          _checked: {
            bg: 'white',
          },
          _before: {
            bg: 'primary.red',
            w: '50%',
            h: '50%',
          },
        },
        _disabled: {
          bg: 'white',
          borderColor: 'grayCCC',
        },
      },
      label: {
        fontWeight: 'bold',
        _disabled: {
          fontWeight: 'bold',
        },
      },
    },
  },
  sizes: {
    lg: {
      control: { w: 6, h: 6 },
      label: { fontSize: 'sm' },
    },
  },
} as const satisfies ThemeComponents['Radio']
