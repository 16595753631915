import { Container, Flex, Heading, Button, Text, Icon } from '@chakra-ui/react'
import { default as NextLink } from 'next/link'
import React, { ComponentProps, FunctionComponent, ReactNode } from 'react'

import TruckIcon from 'assets/truck.svg?component'
import { RoutePaths } from 'config/routes'

type InheritedProps = Omit<ComponentProps<typeof Container>, 'title'>

interface ErrorBaseProps extends InheritedProps {
  title: ReactNode
  description?: ReactNode
}

interface WithOwnButton extends ErrorBaseProps {
  button?: ReactNode
  buttonText?: never
  buttonURL?: never
  buttonOnClick?: never
}

interface WithErrorButton extends ErrorBaseProps {
  button?: never
  /**
   * If `buttonText` is provided, button will be rendered.
   * Please provide `buttonURL` or `buttonOnClick`.
   */
  buttonText?: ReactNode
  /**
   * If `buttonURL` is provided, button will be a link to this URL.
   * @default RoutePaths.home when `buttonOnClick` is not provided
   */
  buttonURL?: string
  /**
   * If `buttonURL` is not provided, this function will be called on button click.
   */
  buttonOnClick?: VoidFunction
}

type ErrorProps = WithOwnButton | WithErrorButton

/**
 * Used for E2E tests.
 */
const TAG = 'Error'

/**
 * @example
 * ```tsx
 * // With button as a link
 * <Error
 *   title="Unauthenticated"
 *   description="You need to login to access this page. You will be redirected in 10 seconds."
 *   buttonText="Login to continue"
 *   buttonURL={url}
 * />
 *
 * // With button with onClick
 * <Error
 *   title="Error while loading something"
 *   description="Occurred an error while loading resource."
 *   buttonText="Try again"
 *   buttonOnClick={response.refetch}
 * />
 *
 * // With own button
 * <Error
 *   title="Empty list"
 *   description="You have no list items. Create one to continue."
 *   button={<CreateListItem />}
 * />
 * ```
 */
const Error: FunctionComponent<ErrorProps> = ({
  buttonText,
  description,
  title,
  buttonOnClick,
  buttonURL = buttonOnClick ? void 0 : RoutePaths.home,
  button,
  ...props
}) => {
  const buttonLinkProps = { as: NextLink, href: buttonURL } as const
  const buttonProps = buttonURL ? buttonLinkProps : {}

  return (
    <Container
      data-testid={TAG}
      alignSelf="center"
      justifySelf="center"
      my="10"
      mx="auto"
      width="full"
      height="full"
      p={0}
      background="primary.white"
      maxH="-webkit-fill-available"
      {...props}
    >
      <Flex height="full" mb="10" direction="column" alignItems="center" justifyContent="center" textAlign="center">
        <Flex
          boxSize="11.5rem"
          borderRadius="50%"
          bgColor="secondary.beige.100"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={TruckIcon} boxSize="5.75rem" color="primary.red" />
        </Flex>
        <Heading as="h1" variant="h1" mb={description ? 4 : 12} mt={8} fontSize="lg">
          {title}
        </Heading>
        {description && (
          <Text textAlign="center" mb="4" maxW="400">
            {description}
          </Text>
        )}
        {button
          ? button
          : !!buttonText && (
              <Button onClick={buttonOnClick} {...buttonProps}>
                {buttonText}
              </Button>
            )}
      </Flex>
    </Container>
  )
}

Error.displayName = TAG

export default Error
