import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import ErrorBoundary from 'components/elements/ErrorBoundary'
import LoadProgressBar from 'components/elements/LoadProgressBar'
import AlertBanner from 'components/modules/AlertBanner'
import Footer from 'components/modules/Footer'
import Head from 'components/modules/Head'
import Header from 'components/modules/Header'
import SEO from 'components/modules/SEO'

export interface LayoutMainProps {
  seo?: Partial<SEO>
}

const TAG = 'LayoutMain'

const LayoutMain: LayoutComponent<LayoutMainProps> = ({ seo }, page) => (
  <>
    <LoadProgressBar />
    <Head />
    <SEO {...seo} />
    <Flex direction="column" maxW="full" h="full" p="0" data-testid={TAG}>
      <Header />
      <AlertBanner />
      <Box as="main" w="full" flexGrow="1" justifyContent="flex-start">
        <ErrorBoundary>{page}</ErrorBoundary>
      </Box>
      <Footer />
    </Flex>
  </>
)

function getLayoutMain(props: LayoutMainProps = {}): GetLayout {
  return (page) => LayoutMain(props, page)
}

export default getLayoutMain
