import type { ThemeComponents } from '@chakra-ui/react'

export const FormError = {
  baseStyle: {
    text: {
      fontSize: '12px', // Setting this in pixels so it does not change if scaling font size
      color: 'primary.red',
      // Used so that form field does not move when error is displayed (fontSize (12px) * lineHeight (1.2) + marginTop (8px) ~= 23px)
      marginBottom: '-23px',
    },
  },
} as const satisfies ThemeComponents['FormError']
