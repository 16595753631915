import type { SystemStyleObject, ThemeComponents } from '@chakra-ui/react'

/**
 * Override button component configuration.
 * @link https://chakra-ui.com/docs/components/button
 * @default `src\node_modules\@chakra-ui\theme\dist\components\button.js`
 */
export const Button = {
  defaultProps: {
    colorScheme: '', // default is `gray` but we want to use different per variant.
    variant: 'primary',
  },
  baseStyle: {
    minH: 12,
    fontSize: 'md',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
    borderRadius: 'sm',
    fontWeight: '700',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    primary: ({ colorScheme: color }) => {
      const colorScheme = color || 'red'

      const _loading: SystemStyleObject = {
        color: 'primary.white',
        backgroundColor: `primary.${colorScheme}`,
      }
      const _disabled: SystemStyleObject = {
        borderColor: 'gray.500',
        bg: 'gray.500',
        color: 'primary.white',
      }
      const _hover: SystemStyleObject = {
        color: 'primary.white',
        backgroundColor: `secondary.${colorScheme}`,
        textDecoration: 'none',
        _disabled,
        _loading,
      }

      return {
        color: 'primary.white',
        transition: 'all 0.2s',
        backgroundColor: `primary.${colorScheme}`,
        borderRadius: 'sm',
        _hover,
        _focusVisible: _hover,
        _disabled: _disabled,
        _loading,
      }
    },
    secondary: ({ colorScheme: color }) => {
      const colorScheme = color || 'red'

      const _loading: SystemStyleObject = {
        color: `primary.${colorScheme}`,
        backgroundColor: 'primary.white',
      }
      const _disabled: SystemStyleObject = {
        borderColor: 'gray.500',
        bg: 'gray.500',
        color: 'primary.white',
      }
      const _hover: SystemStyleObject = {
        color: 'primary.white',
        backgroundColor: `primary.${colorScheme}`,
        textDecoration: 'none',
        _disabled,
        _loading,
      }

      return {
        color: `primary.${colorScheme}`,
        transition: 'all 0.2s',
        borderWidth: 2,
        borderColor: `primary.${colorScheme}`,
        borderRadius: 'sm',
        _hover,
        _focusVisible: _hover,
        _disabled: _disabled,
        _loading,
      }
    },
    link: ({ colorScheme: color }) => {
      const colorScheme = color || 'red'

      const _disabled: SystemStyleObject = {
        color: 'gray.500',
      }
      const _hover: SystemStyleObject = {
        textDecoration: 'underline',
        _disabled,
      }

      return {
        color: `primary.${colorScheme}`,
        transition: 'all 0.2s',
        _hover,
        _focusVisible: _hover,
        _disabled: _disabled,
      }
    },
    ghost: () => {
      const _hover: SystemStyleObject = {
        backgroundColor: 'gray.100',
      }
      return {
        borderRadius: '0.375rem',
        _hover,
        _focusVisible: _hover,
      }
    },
  },
  sizes: {
    md: {
      minH: '3rem',
    },
    lg: {
      minH: '3.5rem',
      fontSize: 'md',
    },
  },
} satisfies ThemeComponents['Button']
