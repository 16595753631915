import type { BusinessUnit } from '@commercetools/platform-sdk'
import type { QueryStatus } from '@tanstack/react-query'
import { createContext } from 'react'

import type { Category } from 'config/categories'

export { BusinessUnit }

export interface BusinessUnitContextValue {
  id: string
  erpId: Database.ERPId
  current: BusinessUnit | undefined
  all: BusinessUnit[]
  categories: Category[] | undefined
  status: QueryStatus
}

const initialValue: BusinessUnitContextValue = {
  id: '',
  erpId: '' as Database.ERPId,
  current: undefined,
  all: [],
  categories: [],
  status: 'loading',
}

export const BusinessUnitContext = createContext<BusinessUnitContextValue>(initialValue)
