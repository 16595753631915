import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'

import SectionLoading from 'components/elements/SectionLoading'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import NoAccessError from 'components/modules/Auth/NoAccessError'
import Unauthenticated from 'components/modules/Auth/Unauthenticated'
import { tokenRequest } from 'config/auth'
import { RoutePaths } from 'config/routes'
import { hasBusinessUnitAccessToPortal, hasCustomerAccessToPortal } from 'utils/hasAccessToPortal'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface GuardAuthorizedProps {
  children: ReactNode
}

const TAG = 'GuardAuthorized'

const GuardAuthorized: FunctionComponent<GuardAuthorizedProps> = ({ children }) => {
  const { isReady, route, push } = useRouter()
  const { instance, inProgress } = useMsal()
  const { status } = useAuthState()
  const { current, all, status: buStatus } = useBusinessUnit()

  useEffect(() => {
    // When the user is not authenticated, we want to redirect them to the login page if:
    // 1. MSAL is not in progress
    const noInteractionInProgress = inProgress === InteractionStatus.None
    // 2. User is not authenticated
    const isUnauthenticated = status === AuthStatus.Unauthenticated
    // 3. Router is ready and user is not on the login page
    const notOnLoginPage = isReady && route !== RoutePaths.login

    const attemptSilentLogin = async () => {
      if (noInteractionInProgress && isUnauthenticated && notOnLoginPage) {
        await instance.ssoSilent(tokenRequest).catch(() => {
          push(RoutePaths.login)
        })
      }
    }
    attemptSilentLogin()
    // When the user is authenticated, we want to redirect them to the home page if they open the login page:
    if (status === AuthStatus.Authenticated && !notOnLoginPage) {
      push(RoutePaths.home)
    }
  }, [inProgress, instance, isReady, push, route, status])

  if (status === AuthStatus.Loading || buStatus === 'loading' || inProgress !== InteractionStatus.None) {
    return <SectionLoading />
  }

  if (status === AuthStatus.Unauthenticated) {
    return <Unauthenticated />
  }

  if (status === AuthStatus.Authenticated) {
    if (all.length === 0 || !hasCustomerAccessToPortal(all)) {
      return <NoAccessError type="customer" />
    }
    if (!hasBusinessUnitAccessToPortal(current)) {
      return <NoAccessError type="businessUnit" />
    }
  }

  return <>{children}</>
}

GuardAuthorized.displayName = TAG

export default GuardAuthorized
