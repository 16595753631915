import type { ComponentStyleConfig } from '@chakra-ui/react'

/**
 * Override drawer component configuration.
 * @link https://chakra-ui.com/docs/components/drawer
 * @default `src\node_modules\@chakra-ui\theme\dist\components\drawer.js`
 */
export const Drawer: ComponentStyleConfig = {
  parts: ['dialog', 'body', 'header'],
  sizes: {
    md: { dialog: { maxW: 'drawer-md' } },
  },
  baseStyle: {
    dialog: {
      boxShadow: '-4px 4px 8px rgba(0, 0, 0, 0.15)',
    },

    closeButton: {
      top: '2.375rem',
      right: { base: 4, lg: 10 },
    },
    header: {
      pt: 10,
      pb: 4,
      pl: { base: 4, lg: 10 },
      pr: { base: 14, sm: 20 },
    },
    body: {
      py: [1, 2],
      px: { base: 4, lg: 10 },
    },
  },
}
