import { Flex, Heading, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { ComponentProps, FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import Link from 'components/elements/Link'
import { Categories, CategoryName, getCategoryImage } from 'config/categories'
import { RoutePaths } from 'config/routes'
import assignParamsToUrl from 'utils/assignParamsToUrl'

interface CategoryItemProps extends Omit<Partial<ComponentProps<typeof Link>>, 'ref'> {
  categoryId: Categories
}

const TAG = 'CategoryItem'

const styles = {
  link: {
    width: 'full',
    textDecoration: 'none',
    minWidth: 0,
    wordBreak: 'break-word',
    _hover: {
      textDecoration: 'none',
      '& h3': {
        color: 'primary.red',
        textDecoration: 'underline',
      },
    },
    _focusVisible: {
      boxShadow: 'none',
      textDecoration: 'none',
      '& h3': {
        color: 'primary.red',
        textDecoration: 'underline',
      },
    },
  },
  image: {
    userSelect: 'none',
    pointerEvents: 'none',
    display: { base: 'none', sm: 'block' },
    minW: { sm: '7rem', lg: '12.5rem' },
    flexShrink: 0,
    width: { base: 120, lg: 200 },
    height: 120,
  },
  texts: {
    flexDirection: 'column',
    paddingInlineStart: { base: 0, sm: 4, xl: 6 },
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  title: {
    color: 'gray.700',
    marginBlockEnd: 2,
  },
  description: {
    color: 'gray.700',
    fontSize: 'xs',
    maxW: '16.5rem',
  },
} as const satisfies SystemStyleInterpolation

const CategoryItem: FunctionComponent<CategoryItemProps> = ({ categoryId, ...props }) => {
  const { t } = useTranslation()

  const categoryName = Categories[categoryId] as CategoryName
  const href = assignParamsToUrl(RoutePaths.connected, { slug: categoryName })
  const imageSrc = getCategoryImage(categoryId)
  const title = t(`components.categories.${categoryName}.title`)

  return (
    <Flex
      // We have to do it because of the bug in Chakra UI Flex component
      {...(props as Omit<ComponentProps<typeof Flex>, 'ref'>)}
      as={Link}
      href={href}
      data-testid={TAG}
      data-category-id={categoryId}
      {...styles.link}
      aria-label={title}
    >
      <Image src={imageSrc} alt="" role="presentation" {...styles.image} />
      <Flex {...styles.texts}>
        <Heading as="h3" size="md" {...styles.title}>
          {title}
        </Heading>
        <Text {...styles.description}>{t(`components.categories.${categoryName}.description`)}</Text>
      </Flex>
    </Flex>
  )
}

CategoryItem.displayName = TAG

export default CategoryItem
