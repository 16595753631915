/**
 * Routes that are accessible only when user is logged in
 */
export const LOGGED_IN_ROUTES = {
  home: '/',
  profile: '/profile/',
  contact: '/contact/',
  equipment: '/equipment/[slug]/',
  connected: '/connected/[[...slug]]/',
  connectedOverview: '/connected/',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only when user is NOT logged in
 */
export const NOT_LOGGED_IN_ROUTES = {
  login: '/login/',
  verifyEmail: '/verify-email/',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only in development environment
 */
const DEV_ROUTES = {
  design: '/design/',
  maintenance: '/maintenance/',
} as const satisfies Record<string, string>

/**
 * API routes.
 * Should always match the routes structure in the `pages/api` folder.
 */
export const API_ROUTES = {
  // auth
  apiAuthRegister: '/api/auth/register/',
  apiAuthVerifyEmail: '/api/auth/verifyEmail/',

  // emails
  apiEmailsSendContactFormEmail: '/api/emails/send-contact-form-email/',

  // database
  apiEquipmentList: '/api/database/list/',
  apiEquipmentDetails: '/api/database/details/',
  apiEquipmentUsage: '/api/database/usage/',
  apiEquipmentConnected: '/api/database/connected/',
  apiEquipmentConnectedCategory: '/api/database/connected-category/',
  apiEquipmentAlertsLevel1: '/api/database/alerts-level-1/',
  apiEquipmentAlertsLevel2: '/api/database/alerts-level-2/',
  apiEquipmentActivity: '/api/database/signals-activity/',
  apiSignalsDefinition: '/api/database/signals-definition/',
  apiSignalsChart: '/api/database/signals-chart/',
  apiCategories: '/api/database/categories/',

  // spare parts
  apiSpareParts: '/api/database/spare-parts/',

  // Contentful
  apiContentTermsAndConditions: '/api/content/terms-and-conditions/',
} as const satisfies Record<string, string>

export const RoutePaths = {
  ...NOT_LOGGED_IN_ROUTES,
  ...LOGGED_IN_ROUTES,
  ...API_ROUTES,
  ...DEV_ROUTES,
} as const satisfies Record<string, string>

export type RoutePathsKeys = keyof typeof RoutePaths
export type RoutePathsValues = (typeof RoutePaths)[RoutePathsKeys]
