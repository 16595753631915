import type { ClientResponse, Customer } from '@commercetools/platform-sdk'

import { CT_MY_CLIENT } from 'commercetools/client/user'

/**
 * @returns currently logged in customer
 */
function getMe(): Promise<ClientResponse<Customer>> {
  return CT_MY_CLIENT.me().get().execute()
}

export default getMe
