import { Icon, useBreakpointValue } from '@chakra-ui/react'
import type { FunctionComponent } from 'react'

import LogoIcon from 'assets/logo.svg?component'
import LogoReversedIcon from 'assets/logoReversed.svg?component'
import Link from 'components/elements/Link'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'
import { SITE_TITLE } from 'config/seo'

interface LogoProps {
  adjustMobile?: boolean
}

/**
 * Used for E2E tests.
 */
const TAG = 'Logo'

const Logo: FunctionComponent<LogoProps> = ({ adjustMobile }) => {
  const isMobile = useBreakpointValue<boolean>({ base: true, md: false }, { fallback: 'md' })
  const { status } = useAuthState()

  return (
    <Link
      display="inline-block"
      href={status === AuthStatus.Authenticated ? RoutePaths.home : RoutePaths.login}
      aria-label={SITE_TITLE}
      data-testid={TAG}
    >
      <Icon
        aria-hidden="true"
        width={{ base: 77, lg: 116 }}
        height="auto"
        as={isMobile && adjustMobile ? LogoReversedIcon : LogoIcon}
        display="flex"
      />
    </Link>
  )
}

Logo.displayName = TAG

export default Logo
