import { Divider, Flex, Link, List, ListItem, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import getCompanyName from 'commercetools/utils/getCompanyName'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import ChangeCompanyButton from 'components/modules/Header/ChangeCompany/ChangeCompanyButton'
import LogoutButton from 'components/modules/Header/LogoutButton'
import { styles as ProfileMenuListStyles } from 'components/modules/Header/ProfileButton/ProfileMenuList'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const TAG = 'MobileProfileMenu'

const styles = {
  mobileList: {
    px: 4,
    borderWidth: 0,
    boxShadow: 'none',
  },
  dividerNarrow: {
    borderBottomWidth: '1px',
    my: 5,
  },
  menuItem: {
    py: 5,
  },
  ...ProfileMenuListStyles,
} as const satisfies SystemStyleInterpolation

interface MobileProfileMenuProps {
  onSelect: () => void
}

const MobileProfileMenu: FunctionComponent<MobileProfileMenuProps> = ({ onSelect }) => {
  const { t } = useTranslation()

  const { status } = useAuthState()
  const { current } = useBusinessUnit()

  return status === AuthStatus.Authenticated ? (
    <Stack as={List} {...styles.mobileList}>
      {current && (
        <>
          <ListItem as={Flex} {...styles.changeCompanyItem} {...styles.menuItem}>
            <Text as="span" {...styles.changeCompanyText}>
              {getCompanyName(current)}
            </Text>
            <ChangeCompanyButton onModalClose={onSelect} />
          </ListItem>

          <Divider {...styles.dividerNarrow} />
        </>
      )}

      <ListItem as={Link} variant="ghost" href={RoutePaths.profile} {...styles.profile} {...styles.menuItem}>
        {t('components.header.profile.menu.profile')}
      </ListItem>

      <Divider {...styles.divider} />

      <LogoutButton mode="menuItem" {...styles.logout} {...styles.menuItem} />
    </Stack>
  ) : null
}

MobileProfileMenu.displayName = TAG

export default MobileProfileMenu
