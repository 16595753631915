import { authFetch } from 'commercetools/client/user'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'

const DEFAULT_INIT = {
  credentials: 'include',
  method: 'GET',
} as const satisfies RequestInit

function handleSuccess<DATA>(response: Response) {
  if (response.ok) {
    return jsonOrEmptyTextMapper<DATA>(response)
  }
  throw response
}

function handleError(error: Response) {
  return jsonOrEmptyTextMapper(error).then((response) => {
    throw response
  })
}

/**
 * Fetches data from the API using the given `input` and `init` parameters.
 * Parses the response as JSON and returns the result.
 *
 * @param {boolean} [authorize=true] - Indicates whether to include authorization headers in the request.
 */
function apiFetch<DATA>(input: RequestInfo | URL, init?: RequestInit, authorize = true): Promise<DATA> {
  return authorize
    ? authFetch(input, { ...DEFAULT_INIT, ...init })
        .then((response) => handleSuccess<DATA>(response))
        .catch((error) => handleError(error))
    : fetch(input, { ...DEFAULT_INIT, ...init })
        .then((response) => handleSuccess<DATA>(response))
        .catch((error) => handleError(error))
}

export default apiFetch
