import { SystemStyleInterpolation, Flex, Text, List, Stack, ListItem, Divider } from '@chakra-ui/react'
import Router from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import type { MenuType } from 'components/modules/Header/MobileMenu'
import MobileSitesMenuButton from 'components/modules/Header/MobileMenu/MobileSitesMenu/MobileSitesMenuButton'
import ProfileMenuButton from 'components/modules/Header/ProfileButton/ProfileMenuButton'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface MobileMenuProps {
  setActiveMenu: (menu: MenuType) => void
  onClose: () => void
}

const TAG = 'MobileMainMenu'

const styles = {
  list: {
    minW: '100vw',
    borderWidth: 0,
    boxShadow: 'none',
  },
  divider: {
    borderBottomWidth: '1px',
    margin: 0,
  },
  menuItem: {
    px: 4,
    py: 4,
    direction: 'column',
    textAlign: 'start',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
    _hover: { bg: 'gray.100' },
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
  },
} as const satisfies SystemStyleInterpolation

const MobileMainMenu: FunctionComponent<MobileMenuProps> = ({ setActiveMenu, onClose }) => {
  const { t } = useTranslation()
  const { categories } = useBusinessUnit()

  const handleRedirect = () => {
    onClose()
    Router.push(RoutePaths.home, void 0, { shallow: false })
  }

  return (
    <Stack as={List} {...styles.list}>
      <ListItem as={Flex} onClick={handleRedirect} {...styles.menuItem} py={5}>
        <Text>{t('components.header.fleetOverview')}</Text>
      </ListItem>
      {categories && (
        <ListItem as={Flex} onClick={() => setActiveMenu('categories')} {...styles.menuItem}>
          <Text>{t('components.header.connected.title')}</Text>
        </ListItem>
      )}
      <Divider {...styles.divider} />
      <ListItem as={Flex} onClick={() => setActiveMenu('profile')} py={2}>
        <ProfileMenuButton isMobile />
      </ListItem>
      <Divider {...styles.divider} />
      <ListItem as={Flex} onClick={() => setActiveMenu('sites')} {...styles.menuItem} py={4}>
        <MobileSitesMenuButton />
      </ListItem>
    </Stack>
  )
}

MobileMainMenu.displayName = TAG

export default MobileMainMenu
