import Head from 'next/head'
import Script from 'next/script'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'GoogleAnalytics'

const GoogleAnalytics: FunctionComponent = () => {
  const NEXT_PUBLIC_GA_ID = process.env.NEXT_PUBLIC_GA_ID

  if (!NEXT_PUBLIC_GA_ID) {
    return null
  }

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://www.google-analytics.com/" crossOrigin="anonymous" />
      </Head>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', '${NEXT_PUBLIC_GA_ID}', 'auto');
ga('send', 'pageview');`,
        }}
      />
    </>
  )
}

GoogleAnalytics.displayName = TAG

export default GoogleAnalytics
