import Head from 'next/head'
import Script from 'next/script'
import React, { FunctionComponent } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'GoogleTagManager'

const GoogleTagManager: FunctionComponent = () => {
  const NEXT_PUBLIC_GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

  if (!NEXT_PUBLIC_GTM_ID) {
    return null
  }

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://www.googletagmanager.com/" crossOrigin="anonymous" />
      </Head>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtm.js?id=${NEXT_PUBLIC_GTM_ID}&l=dataLayer`}
      />
      <Script
        id="google-tag-manager-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
window['dataLayer'] = window['dataLayer'] || [];
window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
function gtag(){window.dataLayer.push(arguments);}
gtag('config', '${NEXT_PUBLIC_GTM_ID}', { page_path: window.location.pathname });`,
        }}
      />
      <noscript
        id="google-tag-manager-iframe"
        dangerouslySetInnerHTML={{
          __html: `<iframe
  title="Google Tag Manager"
  src="https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GTM_ID}"
  height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />
    </>
  )
}

GoogleTagManager.displayName = TAG

export default GoogleTagManager
