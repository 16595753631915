import type { SilentRequest } from '@azure/msal-browser'

import { RoutePaths } from 'config/routes'
import { msalInstance } from 'utils/Providers/Auth'

/**
 * Error AADB2C90080: The provided grant has expired. Please re-authenticate and try again
 */
const providedGrantHasExpiredErrorCode = 'AADB2C90080'

export function getAccessToken(tokenRequest: SilentRequest): Promise<string> {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    return Promise.reject(new Error('No active account'))
  }

  return msalInstance
    .acquireTokenSilent({ ...tokenRequest, account })
    .then((response) => response.accessToken)
    .catch(function (error) {
      if (error.errorMessage.indexOf(providedGrantHasExpiredErrorCode) > -1) {
        msalInstance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login })
      }
      return Promise.reject(new Error('There is a problem with access token'))
    })
}
