import { Icon, Menu, MenuButton, Stack, SystemStyleInterpolation, IconButton } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import CloseIcon from 'assets/close.svg?component'
import MenuIcon from 'assets/menu.svg?component'
import MobileCategoriesMenu from 'components/modules/Header/MobileMenu/MobileCategoriesMenu'
import MobileMainMenu from 'components/modules/Header/MobileMenu/MobileMainMenu'
import MobileProfileMenu from 'components/modules/Header/MobileMenu/MobileProfileMenu'
import MobileSitesMenu from 'components/modules/Header/MobileMenu/MobileSitesMenu'

export type MenuType = 'main' | 'profile' | 'sites' | 'categories'

interface MobileMenuProps {
  onClose: () => void
  onToggle: () => void
  isOpen: boolean
}

const TAG = 'MobileMenu'

const styles = {
  menuButton: { display: { md: 'none' } },
  button: {
    color: 'primary.white',
    borderRightWidth: 1,
    borderRadius: 0,
    borderColor: 'rgba(255,255,255,0.15)',
    marginInlineStart: '0 !important',
    w: 12,
  },
  menu: {
    w: 'full',
    h: '100vh',
    bg: 'primary.white',
    pos: 'fixed',
    mx: '0!important',
    left: 0,
    top: 12,
    zIndex: 'modal',
    pt: 2,
    pb: 6,
    overflowY: 'auto',
  },
  menuWrapper: {
    fontSize: 'sm',
  },
  listItem: {
    px: 4,
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    _hover: { textDecoration: 'underline' },
  },
} as const satisfies SystemStyleInterpolation

const MobileMenu: FunctionComponent<MobileMenuProps> = ({ isOpen, onClose, onToggle }) => {
  const { t } = useTranslation()
  const btnRef = useRef<HTMLButtonElement>(null)
  const [activeMenu, setActiveMenu] = React.useState<MenuType>('main')

  const showBackButton = useMemo(
    () => activeMenu === 'profile' || activeMenu === 'sites' || activeMenu === 'categories',
    [activeMenu],
  )

  const handleToggle = () => {
    onToggle()
    // "Reset" the menu to initial state on close
    if (isOpen) {
      setActiveMenu('main')
    }
  }

  useEffect(() => {
    const closeOnResize = () => {
      if (window.innerWidth < 768) {
        onClose()
      }
    }
    window.addEventListener('resize', closeOnResize)
    closeOnResize()
    return () => window.removeEventListener('resize', closeOnResize)
  }, [onClose])

  const menu = useMemo(() => {
    const onOptionSelected = () => {
      onClose()
      setActiveMenu('main')
    }

    switch (activeMenu) {
      case 'main':
        return <MobileMainMenu setActiveMenu={setActiveMenu} onClose={onClose} />
      case 'profile':
        return <MobileProfileMenu onSelect={onOptionSelected} />
      case 'sites':
        return <MobileSitesMenu onSelect={onOptionSelected} />
      case 'categories':
        return <MobileCategoriesMenu onSelect={onOptionSelected} />
      default:
        return null
    }
  }, [activeMenu, onClose])

  return (
    <Menu closeOnBlur={false} gutter={0}>
      <MenuButton
        as={IconButton}
        isActive={isOpen}
        data-testid={`${TAG}-button`}
        ref={btnRef}
        variant="ghost"
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        aria-controls={`chakra-modal-${TAG}-drawer`}
        aria-label={t('components.header.mobileButton.button')}
        onClick={handleToggle}
        {...styles.menuButton}
        {...styles.button}
        {...(!isOpen && { border: 'none' })}
        // Hover state is not removed: https://github.com/chakra-ui/chakra-ui/issues/6173#issuecomment-1247459629
        sx={{ _hover: {} }}
      >
        <Icon aria-hidden="true" as={isOpen ? CloseIcon : MenuIcon} boxSize={isOpen ? 4 : 6} />
      </MenuButton>

      {isOpen && showBackButton && (
        <IconButton
          variant="ghost"
          aria-label={t('components.header.mobileButton.goBack')}
          icon={<Icon as={ChevronIcon} aria-hidden="true" boxSize={3} transform="rotate(180deg)" />}
          onClick={() => setActiveMenu('main')}
          {...styles.button}
        />
      )}

      <Stack display={{ base: isOpen ? 'flex' : 'none', md: 'none' }} {...styles.menu}>
        <Stack spacing={5} {...styles.menuWrapper}>
          {menu}
        </Stack>
      </Stack>
    </Menu>
  )
}

export default MobileMenu
