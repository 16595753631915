import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override CloseButton component configuration.
 * @link https://chakra-ui.com/docs/components/close-button
 * @default `src\node_modules\@chakra-ui\theme\dist\components\close-button.js`
 */
export const CloseButton = {
  baseStyle: {
    color: 'primary.red',
    insetBlockStart: '2.375rem',
    insetInlineEnd: { base: 4, lg: 10 },
    borderRadius: '50%',
    backgroundColor: 'secondary.beige.100',
    _hover: {
      backgroundColor: 'secondary.beige.600',
    },
    _focusVisible: {
      backgroundColor: 'secondary.beige.600',
    },

    '.chakra-toast__inner > *> &': {
      insetBlockStart: '0.75rem',
      insetInlineEnd: '0.75rem',
    },
  },
} satisfies ThemeComponents['CloseButton']
