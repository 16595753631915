import { type SystemStyleInterpolation, Alert, Flex, AlertIcon, AlertDescription } from '@chakra-ui/react'
import type { FunctionComponent } from 'react'

const TAG = 'AlertBanner'

const styles = {
  alert: { minH: 16, w: 'full', justifyContent: 'center' },
  wrapper: { maxW: 'desktop', w: 'full', align: 'center', px: 10 },
} as const satisfies SystemStyleInterpolation

const AlertBanner: FunctionComponent = () => {
  const isAlertEnabled = process.env.NEXT_PUBLIC_TOAST_ENABLED === 'true' && !!process.env.NEXT_PUBLIC_TOAST_MESSAGE

  return isAlertEnabled ? (
    <Alert status="info" variant="warning" {...styles.alert}>
      <Flex {...styles.wrapper}>
        <AlertIcon />
        <AlertDescription>{process.env.NEXT_PUBLIC_TOAST_MESSAGE}</AlertDescription>
      </Flex>
    </Alert>
  ) : null
}

AlertBanner.displayName = TAG

export default AlertBanner
