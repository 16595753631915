import type { ThemeComponents } from '@chakra-ui/react'

export const Tag = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      container: {
        w: 'max-content',
        backgroundColor: 'primary.red',
        borderRadius: 'lg',
        color: 'white',
        px: 2,
        py: '6px',
        boxShadow: 'lg',
      },
      closeButton: {
        opacity: 1,
      },
      label: {
        fontWeight: 500,
      },
    },
  },
} as const satisfies ThemeComponents['Tag']
