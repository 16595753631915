import type { NodeData } from '@contentful/rich-text-types'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { RoutePaths } from 'config/routes'
import apiFetch from 'utils/apiFetch'

export interface RichTextBlock {
  block: { richText: NodeData }
}

type Arguments = QueryFunctionContext<[queryFnName: 'getTermsAndConditionsContent', lang: string]>

function getTermsOfUseContent({ signal, queryKey: [, lang] }: Arguments): Promise<GraphQLResponse<RichTextBlock>> {
  return apiFetch<GraphQLResponse<RichTextBlock>>(
    `${RoutePaths.apiContentTermsAndConditions}?locale=${lang}`,
    {
      signal,
    },
    false,
  )
}

export default getTermsOfUseContent
