const NORMET_STORE_URL = process.env.NORMET_STORE_URL

/**
 * Retrieves the Normet Portal URL from the environment variable `NORMET_STORE_URL`.
 *
 * @returns {URL | undefined} The URL object if the environment variable is set and valid, otherwise `undefined`.
 *
 * @remarks
 * If the `NORMET_STORE_URL` environment variable is not set, an error message will be logged and the function will return `undefined`.
 * If the `NORMET_STORE_URL` is set but is not a valid URL, an error message will be logged and the function will return `undefined`.
 */
function getNormetStoreURL(): URL | undefined {
  if (!NORMET_STORE_URL) {
    console.error('Could not find URL. Please set environment variable NORMET_STORE_URL.')
    return undefined
  }

  try {
    return new URL(NORMET_STORE_URL)
  } catch (error) {
    console.error('Could not create a URL from NORMET_STORE_URL:', error)
    return undefined
  }
}

export default getNormetStoreURL
