import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { forwardRef } from 'react'

export type ChakraNextLinkProps = NextLinkProps & ChakraLinkProps

const TAG = 'Link'

const Link = forwardRef<HTMLAnchorElement, ChakraNextLinkProps>((props, ref) => (
  <ChakraLink as={NextLink} {...props} ref={ref} />
))

Link.displayName = TAG

export default Link
