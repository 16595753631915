import { QueryClient as ReactQueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode } from 'react'

import shouldRetry from 'utils/Providers/QueryClient/shouldRetry'

interface QueryClientProps {
  children: ReactNode
}

const TAG = 'QueryClient'

export const queryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: shouldRetry,
    },
    mutations: {
      retry: shouldRetry,
    },
  },
})

const QueryClient: FunctionComponent<QueryClientProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

QueryClient.displayName = TAG

export default QueryClient
