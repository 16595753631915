import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override Table component configuration.
 * @link https://chakra-ui.com/docs/components/table
 * @default `src\node_modules\@chakra-ui\theme\dist\components\table.js`
 */
export const Table = {
  baseStyle: {
    th: {
      whiteSpace: 'normal',
      wordBreak: 'normal',
      minWidth: '8rem',
    },
    table: {
      thead: {
        th: {
          textTransform: 'none',
          color: 'primary.black',
          fontSize: 'sm',
          borderBottom: '1px solid',
          borderColor: 'gray.300',
        },
      },
      tr: {
        borderBottom: '1px solid',
        borderColor: 'gray.300',
        td: {
          border: 'none',
        },
      },
    },
  },
} as const satisfies ThemeComponents['Table']
