import type { ThemeComponents } from '@chakra-ui/react'

export const Checkbox = {
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    control: {
      alignSelf: 'start',
      borderWidth: 1,
      borderColor: 'gray.300',
    },
  },
  sizes: {
    md: { control: { height: '6', width: '6' } },
  },
  variants: {
    primary: {
      container: {
        '&:has(input:hover) input:checked + span': {
          backgroundColor: 'gray333',
        },
        '&:has(input:hover) input:not(:checked) + span': {
          backgroundColor: 'gray.200',
        },
        '&:has(input[readonly]) span:not([aria-hidden])': {
          opacity: 0.4,
        },
        _readOnly: { cursor: 'not-allowed' },
      },
      control: {
        backgroundColor: 'white',
        transitionProperty: 'border-color, background-color',
        _checked: {
          borderColor: 'gray333',
          backgroundColor: 'gray333',
          _focusVisible: {
            backgroundColor: 'gray.800',
          },
          _hover: {
            backgroundColor: 'gray333',
            borderColor: 'gray333',
          },
          _readOnly: {
            borderColor: 'gray.200',
            bg: 'gray.200',
            color: 'gray.500',
          },
        },
        _hover: {
          backgroundColor: 'gray.200',
        },
        _focusVisible: {
          backgroundColor: 'gray.200',
        },
        _readOnly: {
          bg: 'gray.100',
          borderColor: 'gray.100',
        },
      },
      label: {
        fontSize: 'sm',
        textAlign: 'start',
        _readOnly: {
          opacity: 0.4,
        },
      },
    },
  },
} as const satisfies ThemeComponents['Checkbox']
