import type { ThemeComponents } from '@chakra-ui/react'

export const Textarea = {
  sizes: {
    xl: {
      minHeight: 168,
    },
  },
  variants: {
    outline: {
      rounded: 'none',
      borderColor: 'grayCCC',
      p: 4,
      _focus: {
        borderColor: 'gray.500',
        boxShadow: 'none',
      },
    },
  },
} as const satisfies ThemeComponents['Textarea']
