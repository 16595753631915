import type { Colors } from '@chakra-ui/react'

export const colors: Colors = {
  grayCCC: '#CCCCCC',
  gray333: '#333333',
  primary: {
    white: '#FFFFFF',
    black: '#000000',
    red: '#D0181F',
    green: '#026D11',
    blue: '#25418D',
  },
  secondary: {
    red: '#9B2C2C',
    green: '#39B54A',
    beige: {
      100: '#F8EEE1',
      600: '#F3D9B7',
    },
  },
  gray: {
    50: '#FCFCFC',
    100: '#F7F7F7',
    200: '#F2F2F2',
    300: '#E5E5E5',
    400: '#B3B3B3',
    500: '#999999',
    600: '#808080',
    700: '#333',
    800: '#1D1D1D',
    900: '#101111',
  },
}
