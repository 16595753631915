type Value = string | number | boolean | null | undefined

/**
 * Util to sort in ascending order.
 *
 * .localeCompare is much slower than this.
 * @link https://stackoverflow.com/a/52369951
 */

function compare(propA: Value, propB: Value): number {
  if (propA !== null && propB === null) {
    return 1
  }

  if (propA === null && propB !== null) {
    return -1
  }

  const a = typeof propA === 'string' ? propA.toLowerCase() : propA ?? ''
  const b = typeof propB === 'string' ? propB.toLowerCase() : propB ?? ''
  return a < b ? -1 : a > b ? 1 : 0
}

export default compare
