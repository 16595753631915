import { Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

const TAG = 'Copyright'

const Copyright: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Text as="span" translate="no" fontSize="md">
      &copy;&nbsp;{new Date().getFullYear()} {t('components.footer.copyright')}
    </Text>
  )
}

Copyright.displayName = TAG

export default Copyright
