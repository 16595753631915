import { Button, Flex, HStack, Icon, MenuButton, Skeleton, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import ProfileIcon from 'assets/user.svg?component'
import getCompanyName from 'commercetools/utils/getCompanyName'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const TAG = 'ProfileMenuButton'

const styles = {
  button: {
    sx: {
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& > span:first-of-type': {
        marginInlineEnd: 0,
      },
    },
    opacity: 1,
    _disabled: {
      opacity: 1,
    },
  },
  leftIcon: { boxSize: 5 },
  rightIcon: { boxSize: 4, display: { base: 'block', md: 'none' } },
  welcome: {
    marginInlineStart: '4',
    direction: 'column',
    textAlign: 'start',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxW: '100%',
    fontSize: 'sm',
  },
  businessUnit: {
    fontWeight: 'normal',
  },
  skeleton: {
    w: '130px',
    h: '10',
  },
} as const satisfies SystemStyleInterpolation

interface ProfileMenuButtonProps {
  isMobile?: boolean
}

const ProfileMenuButton: FunctionComponent<ProfileMenuButtonProps> = ({ isMobile }) => {
  const { t } = useTranslation()

  const { customer, status } = useAuthState()
  const { current } = useBusinessUnit()

  return (
    <MenuButton
      {...styles.button}
      as={Button}
      data-testid={`${TAG}-button`}
      variant="ghost"
      isDisabled={status !== AuthStatus.Authenticated}
      leftIcon={<Icon aria-hidden="true" as={ProfileIcon} {...styles.leftIcon} />}
      rightIcon={<Icon aria-hidden="true" as={ChevronIcon} {...styles.rightIcon} />}
      aria-label={t('components.header.profile.button')}
      {...(isMobile && {
        onClick: (e) => {
          e.preventDefault()
        },
        w: 'full',
      })}
    >
      <HStack justify="space-between" w="full">
        <Flex
          display={{ base: isMobile ? 'flex' : 'none', md: 'flex' }}
          maxW={isMobile ? 'full' : '8rem'}
          {...styles.welcome}
        >
          {status === AuthStatus.Authenticated ? (
            <>
              <Text as="span" {...styles.text}>
                {customer?.firstName
                  ? t('components.header.profile.hi', { firstName: customer.firstName })
                  : t('components.header.profile.welcome-to-normet')}
              </Text>
              <Text as="span" {...styles.text} {...styles.businessUnit}>
                {getCompanyName(current)}
              </Text>
            </>
          ) : (
            <Skeleton {...styles.skeleton} />
          )}
        </Flex>
      </HStack>
    </MenuButton>
  )
}

ProfileMenuButton.displayName = TAG

export default ProfileMenuButton
