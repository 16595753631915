import { Box, Menu, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import ProfileMenuButton from 'components/modules/Header/ProfileButton/ProfileMenuButton'
import ProfileMenuList from 'components/modules/Header/ProfileButton/ProfileMenuList'

const styles = {
  button: { display: { base: 'none', md: 'flex' } },
} as const satisfies SystemStyleInterpolation

const TAG = 'ProfileButton'

const ProfileButton: FunctionComponent = () => {
  const { status } = useAuthState()

  if (status === AuthStatus.Unauthenticated) {
    return null
  }

  return (
    <Box {...styles.button}>
      <Menu isLazy lazyBehavior="keepMounted" id={TAG}>
        <ProfileMenuButton />
        <ProfileMenuList />
      </Menu>
    </Box>
  )
}

ProfileButton.displayName = TAG

export default ProfileButton
