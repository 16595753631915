export const breakpoints = {
  sm: '480px', // 1x
  md: '720px', // 1.5x
  lg: '960px', // 2x
  xl: '1200px', // 2.5x
  '2xl': '1440px', // 3x
  '3xl': '1920px', // 4x
}

export const mediaScreenMax = (key: BreakpointKeys): string => {
  const max = Number(breakpoints[key].match(/\d+/)?.[0]) - 1
  return `@media (max-width: ${max}px)`
}

export const mediaScreenMin = (key: BreakpointKeys): string => `@media (min-width: ${breakpoints[key]})`

export const mediaReducedMotion = (motion: 'reduce' | 'no-preference' = 'reduce'): string =>
  `@media (prefers-reduced-motion: ${motion})`

export type BreakpointKeys = keyof typeof breakpoints
