import { Stack, SystemStyleInterpolation, Link, List, ListItem } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import useNormetSitesLinks from 'hooks/useNormetSitesLinks'

const TAG = 'MobileSitesMenu'

const styles = {
  list: { pt: 5 },
  listItem: {
    px: 5,
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    _hover: { textDecoration: 'underline' },
  },
} as const satisfies SystemStyleInterpolation

interface MobileSitesMenuProps {
  onSelect: () => void
}

const MobileSitesMenu: FunctionComponent<MobileSitesMenuProps> = () => {
  const { t } = useTranslation()
  const sites = useNormetSitesLinks({ addUTMParams: true })

  return (
    <Stack as={List} spacing={5} {...styles.list}>
      {Object.values(sites).map(
        (link) =>
          link.href && (
            <ListItem key={link.siteKey} {...styles.listItem}>
              <Link variant="link" href={link.href} aria-label={t(`components.header.sites.${link.siteKey}.label`)}>
                {t(`components.header.sites.${link.siteKey}.label`)}
              </Link>
            </ListItem>
          ),
      )}
    </Stack>
  )
}

MobileSitesMenu.displayName = TAG

export default MobileSitesMenu
