import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SystemStyleInterpolation,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import RichTextPageContent from 'components/elements/RichTextContent'
import getTermsAndConditionsContent from 'contentful/queries/getTermsAndConditionsContent'

const styles = {
  modalContent: {
    maxW: '560px',
    w: 'full',
  },
  modalBody: {
    maxH: '500px',
    overflow: 'auto',
  },
} as const satisfies SystemStyleInterpolation

interface TermsAndConditionsModalProps extends InheritedModalProps {}

const TAG = 'TermsAndConditionsModal'

const TermsAndConditionsModal: FunctionComponent<TermsAndConditionsModalProps> = ({ onClose, ...props }) => {
  const { t, lang } = useTranslation()
  const { data: response } = useQuery(['getTermsAndConditionsContent', lang], getTermsAndConditionsContent)

  return (
    <Modal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent {...styles.modalContent}>
        <ModalHeader>{t('components.footer.termsOfUse')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody {...styles.modalBody}>
          {response?.data?.block?.richText ? (
            <RichTextPageContent richTextBodyField={response?.data?.block?.richText} />
          ) : (
            <Trans
              i18nKey="components.footer.termsOfUseFallback"
              components={{
                Link: (
                  <Link
                    href={process.env.NORMET_PORTAL_TERMS_OF_USE_URL}
                    variant="ternary"
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                ),
              }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

TermsAndConditionsModal.displayName = TAG

export default TermsAndConditionsModal
