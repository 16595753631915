export type Category = { name: string; id: Categories }

export enum Categories {
  'other-process' = 1,
  'concrete-spraying' = 2,
  'concrete-transportation' = 3,
  'explosives-charging' = 4,
  'lifting-installations' = 5,
  'scaling-and-breaking' = 6,
  'underground-logistics' = 7,
}

export type CategoryName = keyof typeof Categories

// Should be updated once new images will be provided
const CATEGORIES_WITH_IMAGES: ReadonlyArray<Categories & number> = [
  Categories['explosives-charging'],
  Categories['underground-logistics'],
  Categories['concrete-spraying'],
  Categories['lifting-installations'],
  Categories['concrete-transportation'],
]

export function getCategoryImage(categoryId: Categories | null): string {
  const categoryName = categoryId && CATEGORIES_WITH_IMAGES.includes(categoryId) ? Categories[categoryId] : null
  return categoryName ? `/images/category-${categoryName}.jpg` : '/images/hero-connected-fleet.jpg'
}

// We want only slugs, not the ids
export const CATEGORIES_NAME = Object.keys(Categories).filter((key) => !/\d+/.test(key))

export function isCategoryName(slug: string): slug is CategoryName {
  return CATEGORIES_NAME.includes(slug)
}
