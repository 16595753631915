import isDatabaseError from 'database/utils/isDatabaseError'

export enum ErrorCodes {
  NoEquipmentsForSelectedCompany = 'NO_EQUIPMENTS_FOR_SELECTED_COMPANY',
  EquipmentNotExists = 'EQUIPMENT_NOT_EXISTS',
  EquipmentInaccessibleForSelectedCompany = 'EQUIPMENT_INACCESSIBLE_FOR_SELECTED_COMPANY',
  InvalidInput = 'INVALID_INPUT',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NoSignalsForSelectedDateRange = 'NO_SIGNALS_FOR_SELECTED_DATE_RANGE',
  NoActivityForSelectedDateRange = 'NO_ACTIVITY_FOR_SELECTED_DATE_RANGE',
  NoAlertsForSelectedDateRange = 'NO_ALERTS_FOR_SELECTED_DATE_RANGE',
  NoProcessesForSelectedCompany = 'NO_PROCESSES_FOR_SELECTED_COMPANY',
}

export const ERROR_CODE_TO_LANG = {
  [ErrorCodes.EquipmentNotExists]: 'notExists',
  [ErrorCodes.EquipmentInaccessibleForSelectedCompany]: 'notInCompany',
  [ErrorCodes.NoAlertsForSelectedDateRange]: 'noAlerts',
} as const satisfies Partial<Record<ErrorCodes, string>>

export const hasTranslation = (error: unknown): error is Database.Error & { code: keyof typeof ERROR_CODE_TO_LANG } =>
  isDatabaseError(error) && error.code in ERROR_CODE_TO_LANG

/**
 * When backend returns one of these errors, we should not retry the request again.
 */
export const ONE_RETRY_ERRORS_DATABASE: ReadonlyArray<ErrorCodes> = [
  ErrorCodes.NoEquipmentsForSelectedCompany,
  ErrorCodes.EquipmentNotExists,
  ErrorCodes.EquipmentInaccessibleForSelectedCompany,
  ErrorCodes.InvalidInput,
  ErrorCodes.NoSignalsForSelectedDateRange,
  ErrorCodes.NoActivityForSelectedDateRange,
  ErrorCodes.NoAlertsForSelectedDateRange,
  ErrorCodes.NoProcessesForSelectedCompany,
] as const
