import type { ThemeComponents } from '@chakra-ui/react'

export const Tabs = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      tablist: {
        borderBottom: '4px solid',
        borderColor: 'gray.200',
      },
      tabpanel: {
        p: 0,
      },
      tab: {
        mx: { base: 2, sm: 4, md: 6, lg: 12 },
        _focusVisible: {
          boxShadow: 'none',
        },
        borderBottom: '4px solid',
        borderColor: 'transparent',
        marginBottom: '-4px',
        _selected: {
          color: 'primary.red',
          fontWeight: 'bold',
          borderColor: 'primary.red',
        },
      },
    },
  },
} as const satisfies ThemeComponents['Tabs']
