import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { FunctionComponent, ReactNode } from 'react'

import AuthInit from 'components/modules/Auth/AuthInit'
import { msalConfig } from 'config/auth'
import CustomNavigationClient from 'utils/Providers/Auth/NavigationClient'
import BusinessUnitProvider from 'utils/Providers/BusinessUnit'

interface AuthProps {
  children: ReactNode
}

const TAG = 'Auth'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  const navigationClient = new CustomNavigationClient()
  msalInstance.setNavigationClient(navigationClient)

  // This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      'account' in event.payload &&
      event.payload.account
    ) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })
})

const Auth: FunctionComponent<AuthProps> = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    <AuthInit>
      <BusinessUnitProvider>{children}</BusinessUnitProvider>
    </AuthInit>
  </MsalProvider>
)

Auth.displayName = TAG

export default Auth
