import {
  Box,
  Flex,
  List,
  ListItem,
  Link as ChakraLink,
  SystemStyleInterpolation,
  useDisclosure,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useRef } from 'react'

import ButtonModal from 'components/elements/ButtonModal'
import Link from 'components/elements/Link'
import TermsAndConditionsModal from 'components/elements/TermsAndConditionsModal'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { FOOTER_LINKS } from 'components/modules/Footer/config'
import onCookieConsentClick from 'utils/onCookieConsentClick'

const TAG = 'FooterLinks'

const styles = {
  list: {
    marginBlockStart: { base: '3', lg: 0 },
    gap: { base: 3, lg: 10 },
    direction: { base: 'column', lg: 'row' },
    wrap: 'wrap',
  },
  buttonModal: {
    fontWeight: 'normal',
    minH: 'min-content',
  },
} as const satisfies SystemStyleInterpolation

const FooterLinks: FunctionComponent = () => {
  const { t } = useTranslation()
  const btnRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { status } = useAuthState()

  return (
    <Box as="nav" aria-label={t('components.footer.nav')}>
      <Flex as={List} {...styles.list}>
        {status === AuthStatus.Authenticated &&
          FOOTER_LINKS.map(({ href, translateKey, ...link }) => (
            <ListItem key={href}>
              <Link variant="ternary" {...link} href={href} rel="noopener noreferrer">
                {t(translateKey)}
              </Link>
            </ListItem>
          ))}
        <ListItem>
          <ButtonModal ref={btnRef} isOpen={isOpen} onClick={onOpen} variant="link" {...styles.buttonModal}>
            {t('components.footer.termsOfUse')}
          </ButtonModal>
        </ListItem>
        <ListItem>
          <ChakraLink variant="ternary" as="button" type="button" onClick={onCookieConsentClick}>
            {t('components.footer.cookieSettings')}
          </ChakraLink>
        </ListItem>
      </Flex>

      <TermsAndConditionsModal finalFocusRef={btnRef} isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

FooterLinks.displayName = TAG

export default FooterLinks
