import { useRouter } from 'next/router'
import { FunctionComponent, useEffect } from 'react'

/**
 * Used for E2E tests.
 */
const TAG = 'RouteChange'

const RouteChange: FunctionComponent = () => {
  const router = useRouter()

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_GA_ID && !process.env.NEXT_PUBLIC_GTM_ID) {
      return
    }

    const handleRouteChange = (url: string) => window.dataLayer?.push({ event: 'PageView', page_path: url })

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}

RouteChange.displayName = TAG

export default RouteChange
