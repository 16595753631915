import type { ClientResponse, Customer } from '@commercetools/platform-sdk'

import { CT_MY_CLIENT } from 'commercetools/client/user'

interface Variables {
  customerVersion: number
}

function setCustomerLastLoginDate({ customerVersion }: Variables): Promise<ClientResponse<Customer>> {
  return CT_MY_CLIENT.me()
    .post({
      body: {
        version: customerVersion,
        actions: [
          {
            action: 'setCustomField',
            name: 'lastLogin',
            value: new Date().toISOString(),
          },
        ],
      },
    })
    .execute()
}

export default setCustomerLastLoginDate
