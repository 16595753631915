/**
 * Allows use to change the cookie consent settings, managed by Cookiebot.
 * Cookiebot is a third-party service that was added to the website by the Google Tag Manager.
 */
function onCookieConsentClick() {
  if (window.Cookiebot && typeof window.Cookiebot.renew === 'function') {
    return window.Cookiebot.renew()
  }
  console.error('Cookiebot is not configured. Please check the Google Tag Manager configuration.')
}

export default onCookieConsentClick
