import type { BusinessUnitPagedQueryResponse, ClientResponse } from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { CT_MY_CLIENT } from 'commercetools/client/user'

type Arguments = QueryFunctionContext<[queryFnName: 'getMyBusinessUnits']>

/**
 * @returns my business unit.
 * @link https://docs.commercetools.com/api/projects/me-business-units
 */
function getMyBusinessUnits({ signal }: Arguments): Promise<ClientResponse<BusinessUnitPagedQueryResponse>> {
  return CT_MY_CLIENT.me()
    .businessUnits()
    .get({
      queryArgs: {
        limit: 500,
      },
    })
    .execute({ signal })
}

export default getMyBusinessUnits
