import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

function LoadProgressBar() {
  const router = useRouter()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let incrementTimer: ReturnType<typeof setInterval>
    let resetTimer: ReturnType<typeof setTimeout>

    function increment() {
      const timeout = Math.round(Math.random() * 300)

      incrementTimer = setInterval(() => {
        setProgress((progress) => {
          const percent = Math.round(Math.random() * 9) // Number from [0 to 9]
          const next = Math.min(progress + percent, 90 - progress)
          if (progress >= 90) {
            clearInterval(incrementTimer)
            return 90 + percent
          }
          return progress + next
        })
      }, timeout)
    }

    function start() {
      clearTimeout(resetTimer)
      clearInterval(incrementTimer)
      setProgress(() => 1)

      increment()
    }

    function complete() {
      clearInterval(incrementTimer)
      setProgress(() => 100)

      resetTimer = setTimeout(() => setProgress(() => 0), 100)
    }

    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', complete)
    router.events.on('routeChangeError', complete)

    return () => {
      clearInterval(incrementTimer)
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', complete)
      router.events.off('routeChangeError', complete)
    }
  }, [router.events])

  return (
    <Box
      pos="fixed"
      left="0"
      top="0"
      w="full"
      zIndex="modal"
      h="0.25rem"
      transitionDuration=".3s"
      bg="primary.red"
      opacity={progress > 5 && progress < 100 ? 1 : 0}
      transform={`scaleX(${progress}%)`}
      transformOrigin="left"
    />
  )
}

export default LoadProgressBar
