import { Icon, SystemStyleInterpolation, Text, HStack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import MoreIcon from 'assets/more.svg?component'

const TAG = 'MobileSitesMenuButton'

const styles = {
  wrapper: { justifyContent: 'space-between', width: 'full', h: 12 },
  text: { fontWeight: 'bold' },
} as const satisfies SystemStyleInterpolation

const MobileSitesMenuButton: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <HStack {...styles.wrapper}>
      <HStack spacing={4}>
        <Icon aria-hidden="true" as={MoreIcon} boxSize={6} />
        <Text {...styles.text}>{t('components.header.normet-websites')}</Text>
      </HStack>
      <Icon aria-hidden="true" as={ChevronIcon} boxSize={4} />
    </HStack>
  )
}

MobileSitesMenuButton.displayName = TAG

export default MobileSitesMenuButton
