import { HStack, List, ListItem, SystemStyleInterpolation } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'

import SiteLink from 'components/modules/Header/Sites/SiteLink'
import useNormetSitesLinks from 'hooks/useNormetSitesLinks'

const styles = {
  list: {
    display: { base: 'none', md: 'flex' },
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'Sites'

const Sites: FunctionComponent = () => {
  const sites = useNormetSitesLinks({ addUTMParams: true })

  return (
    <HStack as={List} spacing={10} {...styles.list}>
      {Object.values(sites).map(
        (link) =>
          link.href && (
            <ListItem key={link.siteKey}>
              <SiteLink {...link} />
            </ListItem>
          ),
      )}
    </HStack>
  )
}

Sites.displayName = TAG

export default Sites
