import { StackProps, Stack } from '@chakra-ui/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { NodeData } from '@contentful/rich-text-types'

const TAG = 'RichTextContent'

type Props = StackProps & { richTextBodyField: NodeData | undefined }

const RichTextContent = ({ richTextBodyField, ...props }: Props) => {
  if (!richTextBodyField?.json) {
    return null
  }

  return (
    <Stack data-contentful-field-id="primaryRichText" {...props}>
      {documentToReactComponents(richTextBodyField.json)}
    </Stack>
  )
}

RichTextContent.displayName = TAG

export default RichTextContent
