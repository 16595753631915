import { ButtonProps, SystemStyleInterpolation, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useRef } from 'react'

import ButtonModal from 'components/elements/ButtonModal'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface ChangeCompanyButtonProps extends ButtonProps {
  onModalClose?: () => void
}

const ChangeCompanyModal = dynamic(
  () =>
    import(/* webpackChunkName: "ChangeCompanyModal" */ 'components/modules/Header/ChangeCompany/ChangeCompanyModal'),
)

const TAG = 'ChangeCompanyButton'

const styles = {
  button: {
    fontSize: 'sm',
    h: 'auto',
    minH: 'auto',
    fontWeight: 'normal',
    _hover: { textDecoration: 'underline' },
    _focusVisible: { textDecoration: 'underline' },
  },
} as const satisfies SystemStyleInterpolation

const ChangeCompanyButton: FunctionComponent<ChangeCompanyButtonProps> = ({ onModalClose, ...props }) => {
  const { t } = useTranslation()

  const businessUnit = useBusinessUnit()
  const hasMoreThanOneCompany = businessUnit.all.length > 1

  const btnRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!hasMoreThanOneCompany) {
    return null
  }

  const variant = props.variant || 'unstyled'
  const buttonStyles = variant === 'unstyled' ? styles.button : {}

  const handleModalClose = () => {
    if (onModalClose) {
      onModalClose()
    }

    onClose()
  }

  return (
    <>
      <ButtonModal
        ref={btnRef}
        isOpen={isOpen}
        onClick={onOpen}
        testid={TAG}
        variant="unstyled"
        {...buttonStyles}
        {...props}
      >
        {t('components.header.profile.menu.changeCompany')}
      </ButtonModal>

      <ChangeCompanyModal
        finalFocusRef={btnRef}
        id={TAG}
        isOpen={isOpen}
        onClose={handleModalClose}
        data-testid={`${TAG}-modal`}
      />
    </>
  )
}

ChangeCompanyButton.displayName = TAG

export default ChangeCompanyButton
