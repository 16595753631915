import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'

import setCustomerBusinessUnit from 'commercetools/businessUnits/setCustomerBusinessUnit'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import useStateCache from 'hooks/useStateCache'
import { BUSINESS_UNIT_STORAGE_ID } from 'utils/Providers/BusinessUnit/config'

/**
 * @returns Customer selected business unit id.
 * @private for BusinessUnitProvider
 */
function useMyBusinessUnitId(defaultBUId: string | undefined, allBusinessUnits: ReadonlyArray<string>): string {
  /**
   * We need to use the business unit id from storage when the customer query is still loading.
   * But when the customer query is already loaded, we should use the business unit id from the query data,
   * because this is the most up-to-date and valid user business unit id.
   * When `customerBU` is the same as `businessUnitIdSaved`, it means that we gained on that simple optimization,
   * because queries that relays on that key will not be called again because `queryKey` didn't change.
   */
  const [businessUnitIdSaved, setBusinessUnitId] = useStateCache(BUSINESS_UNIT_STORAGE_ID, '')
  const { status, customer, invalidate } = useAuthState()

  const fields = (status === AuthStatus.Authenticated ? customer.custom?.fields : {}) || {}
  const customerBU = fields.ctSelectedBusinessUnit?.id || /* Backward compatibility */ fields.selectedCompany?.id || ''
  const businessUnitId: string = customerBU || businessUnitIdSaved || defaultBUId || ''

  /**
   * We want to update the business unit id in storage when the user is authenticated
   * in order to keep the business unit id in sync with the user data.
   */
  useEffect(() => {
    if (status === AuthStatus.Authenticated) {
      setBusinessUnitId(businessUnitId)
    }
  }, [businessUnitId, setBusinessUnitId, status])

  /**
   * We want to update the business unit id in Commercetools when the user is authenticated
   * and value in customer custom field is empty.
   */
  const { mutate } = useMutation({
    mutationFn: setCustomerBusinessUnit,
    onSuccess: invalidate,
  })

  const customerVersion = customer?.version
  useEffect(() => {
    if (status === AuthStatus.Authenticated && customerVersion && !customerBU && businessUnitId) {
      mutate({ businessUnitId, customerVersion })
    }
  }, [businessUnitId, customerBU, customerVersion, mutate, status])

  /**
   * We want to update the business unit id in Commercetools when the user is authenticated
   * and value in customer custom field is not empty but is different from the current business unit id.
   */
  useEffect(() => {
    if (
      status === AuthStatus.Authenticated &&
      customerVersion &&
      customerBU &&
      businessUnitId &&
      allBusinessUnits.length > 0 &&
      !allBusinessUnits.includes(customerBU)
    ) {
      mutate({ businessUnitId: defaultBUId || businessUnitId, customerVersion })
    }
  }, [allBusinessUnits, businessUnitId, customerBU, customerVersion, defaultBUId, mutate, status])

  return businessUnitId
}

export default useMyBusinessUnitId
