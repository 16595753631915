import dynamic, { DynamicOptions, Loader } from 'next/dynamic'

import hasProperty from 'utils/hasProperty'

export type DynamicComponent<P> = React.FunctionComponent<P> & {
  preload: PromiseVoidFunction
}

function getPreloadFunction<P>(component: DynamicComponent<P>): PromiseVoidFunction {
  return async () => {
    const preload =
      hasProperty(component, 'render') &&
      hasProperty(component.render, 'preload') &&
      typeof component.render.preload === 'function'
        ? component.render.preload
        : Promise.reject

    try {
      await preload()
    } catch (error) {
      console.error('Unable to preload component', error)
    }
  }
}

/**
 * Small optimization to preload dynamic components.
 * Thanks to that, we can preload components on the client side before it is rendered for the first time.
 *
 * @example
 * ```ts
 * const YourComponent = dynamicWithPreload(() => import('components/YourComponent'))
 *
 * const YourPage = () => {
 *   useEffect(() => {
 *     YourComponent.preload()
 *   }, [])
 *
 *   return <YourComponent />
 * }
 * ```
 */
function dynamicWithPreload<P = unknown>(
  dynamicOptions: DynamicOptions<P> | Loader<P>,
  options?: DynamicOptions<P>,
): DynamicComponent<P> {
  const Component = dynamic(dynamicOptions, { ...options }) as DynamicComponent<P>

  Component.preload = getPreloadFunction<P>(Component)

  return Component
}

export default dynamicWithPreload
