import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk'
import type { ByProjectKeyRequestBuilder as ApiRoot } from '@commercetools/platform-sdk/dist/declarations/src/generated/client/by-project-key-request-builder'
import { ClientBuilder } from '@commercetools/sdk-client-v2'

import { getAccessToken } from 'commercetools/client/token'
import { tokenRequest } from 'config/auth'

/**
 * HOC for fetch with access token.
 */
function fetchWithToken(accessToken: string) {
  return (input: RequestInfo | URL, init?: RequestInit) => {
    const headers = new Headers(init?.headers)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return fetch(input, { ...init, headers })
  }
}

/**
 * Client side fetch with access token
 */
export async function authFetch(input: RequestInfo | URL, init?: RequestInit) {
  const accessToken = await getAccessToken(tokenRequest)
  return fetchWithToken(accessToken)(input, init)
}

/**
 * Use only in SSR or API routes
 * @example
 * ```tsx
 * // SSR
 * export const getServerSideProps: GetServerSideProps = async (context) => {
 *   const accessToken = await getAccessTokenFromContextReq(context.req)
 *   const client = createCustomerAPIClient(accessToken)
 *   const products = await client.products().get().execute()
 *
 *   return {
 *    props: { products },
 *   }
 * }
 * ```
 *
 * @example
 * ```tsx
 * // API routes
 * async function getProducts(req: NextApiRequest, res: NextApiResponse): Promise<void> {
 *  // AccessToken from Authorization header
 *  const accessToken = req.headers.authorization?.split(' ')[1]
 *  // AccessToken from cookies
 *  const accessToken = req.cookies.mcAccessToken
 *
 *  const client = createCustomerAPIClient(accessToken)
 *  const products = await client.products().get().execute()
 *
 *  res
 *    .setHeader('Content-Type', 'application/json')
 *    .status(200)
 *    .json(products)
 * }
 * ```
 */
export function createCustomerAPIClient(accessToken: string): ApiRoot {
  return createApiBuilderFromCtpClient(
    new ClientBuilder()
      .withProjectKey(process.env.CTP_PROJECT_KEY)
      .withHttpMiddleware({ host: process.env.CTP_API_URL, fetch: fetchWithToken(accessToken) })
      .build(),
  ).withProjectKey({ projectKey: process.env.CTP_PROJECT_KEY })
}

/**
 * Use only on Client side.
 * **That client will not work on SSR or API routes!!!**
 * @see {@link CT_MY_CLIENT}
 */
function createCustomerClient(): ApiRoot {
  return createApiBuilderFromCtpClient(
    new ClientBuilder()
      .withProjectKey(process.env.CTP_PROJECT_KEY)
      .withHttpMiddleware({ host: process.env.CTP_API_URL, fetch: authFetch })
      .build(),
  ).withProjectKey({ projectKey: process.env.CTP_PROJECT_KEY })
}

/**
 * Use only on Client side.
 * **That client will not work on SSR or API routes!!!**
 *
 * @example
 * ```tsx
 * useEffect(() => {
 *   CT_MY_CLIENT.products().get().execute().then((res) => {
 *     console.log(res.body)
 *   })
 * }, [])
 * ```
 */
export const CT_MY_CLIENT = createCustomerClient()
