/* eslint-disable react/jsx-no-target-blank */
import type { SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { ComponentProps, FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import { beforeElement, buttonStyles } from 'components/modules/Header/Navigation/styles'
import type { SiteKey } from 'hooks/useNormetSitesLinks'

interface SiteLinkProps extends ComponentProps<typeof Link> {
  siteKey: SiteKey
}

const TAG = 'SiteLink'

const styles = {
  button: buttonStyles,
  underline: {
    _before: {
      md: beforeElement,
    },
  },
} as const satisfies SystemStyleInterpolation

const SiteLink: FunctionComponent<SiteLinkProps> = ({ href, siteKey }) => {
  const { t } = useTranslation()

  const label = t(`components.header.sites.${siteKey}.label`)
  return (
    <Link {...styles.button} {...(siteKey === 'portal' ? { ...styles.underline } : {})} href={href} aria-label={label}>
      {label}
    </Link>
  )
}

SiteLink.displayName = TAG

export default SiteLink
