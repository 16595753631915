import { Container, Flex, SystemStyleInterpolation } from '@chakra-ui/react'
import { FunctionComponent, memo } from 'react'

import Copyright from 'components/modules/Footer/Copyright'
import FooterLinks from 'components/modules/Footer/FooterLinks'

const styles = {
  footer: {
    mt: 6,
    bgColor: 'white',
    maxW: 'desktop',
    px: { base: 6, lg: 10 },
  },
  container: {
    borderBlockStart: '4px solid',
    borderColor: 'primary.black',
    py: 4,
    alignItems: { base: 'flex-start', lg: 'center' },
    justifyContent: 'space-between',
    flexDir: { base: 'column', lg: 'row' },
  },
} as const satisfies SystemStyleInterpolation

const Footer: FunctionComponent = () => (
  <Container as="footer" {...styles.footer}>
    <Flex {...styles.container}>
      <Copyright />
      <FooterLinks />
    </Flex>
  </Container>
)

export default memo(Footer)
