import type { QueryFunctionContext } from '@tanstack/react-query'

import type { Category } from 'config/categories'
import { RoutePaths } from 'config/routes'
import apiFetch from 'utils/apiFetch'

type Arguments = QueryFunctionContext<[queryFnName: 'getCategories', erpId: Database.ERPId]>

type CategoriesResponse = Category[]

function getCategories({ signal, queryKey: [, erpId] }: Arguments): Promise<CategoriesResponse> {
  if (!erpId) {
    return Promise.resolve([])
  }

  const params = new URLSearchParams({ erpId }).toString()
  return apiFetch<CategoriesResponse>(`${RoutePaths.apiCategories}?${params}`, { signal })
}

export default getCategories
