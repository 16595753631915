import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Custom form input component
 * @see `src\components\elements\FormInput\index.tsx`
 *
 * @link https://chakra-ui.com/docs/components/form-control
 * @default `src\node_modules\@chakra-ui\theme\dist\components\input.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-control.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-label.js`
 * @default `src\node_modules\@chakra-ui\theme\dist\components\form-error.js`
 */
export const FormInput = {
  variants: {
    basic: {
      control: {},
      label: {
        fontSize: 'sm',
        lineHeight: 1.71,
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        mx: 3,
        px: 1,
        my: 4,
        sx: {
          // input has left element
          'div ~ &': {
            left: 6,
          },
        },
      },
      input: {
        borderColor: 'grayCCC',
        borderWidth: 1,
        borderRadius: 'sm',
        minHeight: '55px',
        fontSize: 'md',
        fontWeight: 'normal',
        backgroundColor: 'white',
        _focus: {
          borderColor: 'gray.500',
          boxShadow: 'none',
        },
        _autofill: {
          // Hack to override chrome autocomplete color
          font: 'inherit',
          boxShadow: '0 0 0px 1000px white inset',
          transition: 'background-color 5000s ease-in-out 0s',
        },
        _invalid: {
          backgroundColor: 'secondary.beige.100',
          borderColor: 'secondary.beige.600',
          boxShadow: `0 0 0 1px 'secondary.beige.600'`,
        },
        sx: {
          '&::-ms-reveal': {
            display: 'none',
          },
          '&:placeholder-shown ~ div:has(*[data-clear-field])': {
            opacity: 0,
            visibility: 'hidden',
          },
        },
      },
      error: {
        position: 'absolute',
        top: 'calc(100% - 0.375rem)',
        textAlign: 'start',
      },
      rightElement: {
        height: 'calc(100% - 2px)',
        top: '1px',
        right: '1px',
        transitionProperty: 'opacity, visibility',
        transitionDuration: '0.2s',
        sx: {
          '&:empty': {
            display: 'none',
          },
        },
      },
      leftElement: {
        height: 'calc(100% - 2px)',
        top: '1px',
        left: '1px',
        sx: {
          '&:empty': {
            display: 'none',
          },
        },
      },
    },
  },
} as const satisfies ThemeComponents['FormInput']
