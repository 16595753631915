export const Heading = {
  sizes: {
    xl: { fontSize: '2xl', lineHeight: 1.17 },
    lg: { fontSize: 'xl', lineHeight: 1.25 },
    md: { fontSize: 'lg', lineHeight: 1.33 },
    sm: { fontSize: 'md', lineHeight: 1.5 },
    xs: { fontSize: 'sm', lineHeight: 1.57 },
    xxs: { fontSize: 'xs', lineHeight: 1.66 },
  },
}
