import type { ThemeComponents } from '@chakra-ui/react'

export const Link = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      textDecoration: 'underline',
      color: 'primary.red',
    },
    secondary: {
      textDecoration: 'underline',
      color: 'primary.black',
    },
    ternary: {
      textDecoration: 'none',
      color: 'primary.red',
      _hover: {
        textDecoration: 'underline',
      },
      _focusVisible: {
        textDecoration: 'underline',
      },
    },
  },
} as const satisfies ThemeComponents['FormError']
