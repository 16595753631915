import { SystemStyleInterpolation, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, ReactNode, useId, useRef } from 'react'

import ButtonModal from 'components/elements/ButtonModal'
import { buttonStyles } from 'components/modules/Header/Navigation/styles'

const NavigationDrawer = dynamic(
  () => import(/* webpackChunkName: "NavigationDrawer" */ 'components/modules/Header/Navigation/NavigationDrawer'),
)

interface NavigationButtonProps {
  children: ReactNode
  drawer: ReactNode
  variant?: 'link'
}

const TAG = 'NavigationButton'

const styles = {
  button: buttonStyles,
  link: {
    color: 'primary.white',
    fontWeight: 'normal',
    minH: 'min-content',
  },
} as const satisfies SystemStyleInterpolation

const NavigationButton: FunctionComponent<NavigationButtonProps> = ({ children, drawer, variant }) => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()

  const id = `${TAG}-${useId()}`

  const isDesktop = useBreakpointValue<boolean>({ base: false, md: true }, { fallback: 'md' })

  return (
    <>
      <ButtonModal
        ref={btnRef}
        isOpen={isOpen}
        onClick={onToggle}
        testid={TAG}
        aria-controls={id}
        {...(variant === 'link' ? styles.link : styles.button)}
      >
        {children}
      </ButtonModal>

      <NavigationDrawer isOpen={!!isDesktop && isOpen} onClose={onClose} finalFocusRef={btnRef} size="full">
        {drawer}
      </NavigationDrawer>
    </>
  )
}

NavigationButton.displayName = TAG

export default NavigationButton
