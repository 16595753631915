import { useMsal } from '@azure/msal-react'
import { Button, ButtonProps, MenuItem } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback } from 'react'

import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'

interface LogoutButtonProps extends ButtonProps {
  mode?: 'button' | 'menuItem'
}

const TAG = 'LogoutButton'

const LogoutButton: FunctionComponent<LogoutButtonProps> = ({ mode = 'button', ...props }) => {
  const { t } = useTranslation()

  const { asPath } = useRouter()
  const { status } = useAuthState()
  const { instance } = useMsal()

  const logout = useCallback<VoidFunction>(async () => {
    const redirectURI = new URL(`${process.env.NEXT_PUBLIC_SITE_URL}/${RoutePaths.login}`)
    redirectURI.searchParams.set('redirect', asPath)
    const account = instance.getActiveAccount()
    await instance.logoutRedirect({ account, postLogoutRedirectUri: redirectURI.href })
  }, [asPath, instance])

  if (status === AuthStatus.Authenticated) {
    return mode === 'button' ? (
      <Button variant="link" onClick={logout} data-testid={TAG} {...props}>
        {t('components.header.profile.menu.logout')}
      </Button>
    ) : (
      <MenuItem onClick={logout} data-testid={TAG} {...props}>
        {t('components.header.profile.menu.logout')}
      </MenuItem>
    )
  }

  return null
}

LogoutButton.displayName = TAG

export default LogoutButton
