import type { ThemeComponents } from '@chakra-ui/react'

export const Alert = {
  baseStyle: {
    container: {
      textAlign: 'start',
    },
  },
  variants: {
    success: {
      container: {
        color: 'primary.white',
        bg: 'secondary.green',
        borderRadius: '2px',
      },
    },
    warning: {
      container: {
        color: 'primary.black',
        bg: 'secondary.beige.100',
      },
    },
  },
} as const satisfies ThemeComponents['Alert']
