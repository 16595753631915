import { Flex, MenuDivider, MenuItem, MenuList, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import getCompanyName from 'commercetools/utils/getCompanyName'
import Link from 'components/elements/Link'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import ChangeCompanyButton from 'components/modules/Header/ChangeCompany/ChangeCompanyButton'
import LogoutButton from 'components/modules/Header/LogoutButton'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const TAG = 'ProfileMenuList'

export const styles = {
  list: {
    p: '4',
    maxW: '18rem',
  },
  divider: {
    height: '.25rem',
    border: 'none',
    backgroundColor: 'gray.300',
  },
  changeCompanyItem: {
    direction: 'column',
    textAlign: 'start',
    alignItems: 'flex-start',
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
    p: 0,
  },
  changeCompanyText: {
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  profile: {
    w: 'auto',
    display: 'inline-flex',
    fontSize: 'sm',
    _focusVisible: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
    px: 0,
  },
  logout: {
    w: 'auto',
    fontSize: 'sm',
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
    _hover: { textDecoration: 'underline' },
    _focusVisible: { textDecoration: 'underline' },
    px: 0,
  },
} as const satisfies SystemStyleInterpolation

const ProfileMenuList: FunctionComponent = () => {
  const { t } = useTranslation()

  const { status } = useAuthState()
  const { current } = useBusinessUnit()

  return status === AuthStatus.Authenticated ? (
    <MenuList {...styles.list}>
      {current && (
        <>
          <MenuItem as={Flex} {...styles.changeCompanyItem}>
            <Text as="span" {...styles.changeCompanyText}>
              {getCompanyName(current)}
            </Text>
            <ChangeCompanyButton />
          </MenuItem>

          <MenuDivider {...styles.divider} />
        </>
      )}

      <MenuItem as={Link} href={RoutePaths.profile} {...styles.profile}>
        {t('components.header.profile.menu.profile')}
      </MenuItem>

      <MenuDivider {...styles.divider} />

      <LogoutButton mode="menuItem" {...styles.logout} />
    </MenuList>
  ) : null
}

ProfileMenuList.displayName = TAG

export default ProfileMenuList
