/* eslint-disable @typescript-eslint/no-var-requires */
const hoistNonReactStatics = require('hoist-non-react-statics')

/**
 * @constant
 */
const LOCALES = [
  'en', // Global, English
]

/**
 * @type {RequiredFields<import('next-translate').I18nConfig, 'locales' | 'defaultLocale' | 'localesToIgnore'>}
 */
const CONFIG = {
  defaultLocale: 'default',
  locales: ['default', ...LOCALES],
  localesToIgnore: [],
  loadLocaleFrom: async (lang, ns) => require(`./locales/${lang === 'default' ? 'en' : lang}/${ns}.json`),
  defaultNS: 'common',
  pages: {
    '*': ['common'],
    'rgx:^\\/login\\/': ['auth'], // Starts with `/login/`
    'rgx:^\\/verify-email\\/': ['auth'], // Starts with `/verify-email/`
    'rgx:^\\/equipment\\/': ['equipment', 'signals'], // Starts with `/equipment/`
    'rgx:^\\/connected\\/': ['connected'], // Starts with `/connected/`
  },
  interpolation: {
    /**
     * Format value using formatters.
     * @link https://github.com/aralroca/next-translate#9-formatter
     *
     * @example
     * How to use it?
     * - `var` - means your variable name.
     * ```jsonc
     * {
     *   "i18n-number": "Hello {{var, number}}",
     *   "i18n-date": "Hello {{var, date}}",
     *   "i18n-time": "Hello {{var, time}}",
     *   "i18n-datetime": "Hello {{var, datetime}}"
     * }
     * ```
     *
     * @param {string | number} value
     * @param {string} format
     * @param {ArrayValues<typeof LOCALES>} lang
     * @returns {string}
     *
     * @inheritDoc
     */
    format(value, format, lang) {
      switch (format) {
        case 'number':
          return new Intl.NumberFormat(lang).format(value)
        case 'date':
          return new Date(value).toLocaleDateString(lang)
        case 'time':
          return new Date(value).toLocaleTimeString(lang)
        case 'datetime':
          return new Date(value).toLocaleString(lang)

        default:
          return value
      }
    },
  },
  // To make layouts work:
  staticsHoc: hoistNonReactStatics,
}

module.exports = CONFIG
