import type { BusinessUnit } from '@commercetools/platform-sdk'
import { useQuery } from '@tanstack/react-query'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'

import getMyBusinessUnits from 'commercetools/businessUnits/getMyBusinessUnits'
import getCompanyName from 'commercetools/utils/getCompanyName'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import getCategories from 'database/equipment/getCategories'
import compare from 'utils/compare'
import { BusinessUnitContext, BusinessUnitContextValue } from 'utils/Providers/BusinessUnit/context'
import useMyBusinessUnitId from 'utils/Providers/BusinessUnit/useMyBusinessUnitId'

interface BusinessUnitProviderProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'BusinessUnitProvider'

const BusinessUnitProvider: FunctionComponent<BusinessUnitProviderProps> = ({ children }) => {
  const { customer, status } = useAuthState()

  const response = useQuery(['getMyBusinessUnits'], getMyBusinessUnits, {
    enabled: status === AuthStatus.Authenticated,
  })

  const myBusinessUnits = useMemo<BusinessUnit[]>(
    () => response.data?.body.results || [],
    [response.data?.body.results],
  )

  const myBusinessUnitsIds = useMemo<string[]>(() => myBusinessUnits.map((bu) => bu.id), [myBusinessUnits])

  // Backward compatibility
  const defaultBUIdFromCustomer: string | undefined = customer?.custom?.fields?.companies?.[0]?.id
  const defaultBUIdFromB2B: string | undefined = response.data?.body.results?.[0]?.id
  const defaultBUId = defaultBUIdFromB2B || defaultBUIdFromCustomer
  const businessUnitId = useMyBusinessUnitId(defaultBUId, myBusinessUnitsIds)

  const current = useMemo<BusinessUnit | undefined>(() => {
    const defaultBusinessUnit = myBusinessUnits[0]
    return myBusinessUnits.find((bu) => bu.id === businessUnitId) || defaultBusinessUnit
  }, [businessUnitId, myBusinessUnits])

  const erpId = current?.custom?.fields.erpAccountNumber || ''

  const { data: categories } = useQuery(['getCategories', erpId], getCategories, {
    enabled: !!erpId,
  })

  const value = useMemo<BusinessUnitContextValue>(() => {
    return {
      all: myBusinessUnits.sort((a, b) => compare(getCompanyName(a), getCompanyName(b))),
      current,
      id: current?.id || businessUnitId,
      erpId,
      categories,
      status: response.status,
    }
  }, [businessUnitId, categories, current, erpId, myBusinessUnits, response.status])

  return <BusinessUnitContext.Provider value={value}>{children}</BusinessUnitContext.Provider>
}

BusinessUnitProvider.displayName = TAG

export default BusinessUnitProvider
