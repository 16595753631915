import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Error from 'components/elements/Error'
import ChangeCompanyButton from 'components/modules/Header/ChangeCompany/ChangeCompanyButton'

interface NoAccessErrorProps {
  type: 'customer' | 'businessUnit'
}

const TAG = 'NoAccessError'

const NoAccessError: FunctionComponent<NoAccessErrorProps> = ({ type }) => {
  const { t } = useTranslation()

  if (type === 'customer') {
    return (
      <Error
        title={t('components.auth.no-access.title')}
        description={t('components.auth.no-access.description')}
        buttonText={t('components.auth.no-access.button')}
        buttonURL={process.env.NORMET_NO_ACCESS_TO_PORTAL_URL}
      />
    )
  }
  return (
    <Error
      title={t('components.auth.no-business-unit-access.title')}
      description={t('components.auth.no-business-unit-access.description')}
      button={<ChangeCompanyButton variant="primary" />}
    />
  )
}

NoAccessError.displayName = TAG

export default NoAccessError
