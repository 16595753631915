import { ChakraTheme, extendTheme, theme as base } from '@chakra-ui/react'

import { Alert } from 'theme/components/alert'
import { Button } from 'theme/components/button'
import { Checkbox } from 'theme/components/checkbox'
import { CloseButton } from 'theme/components/closeButton'
import { Divider } from 'theme/components/divider'
import { Drawer } from 'theme/components/drawer'
import { Form } from 'theme/components/form'
import { FormError } from 'theme/components/formError'
import { FormInput } from 'theme/components/formInput'
import { Heading } from 'theme/components/heading'
import { Link } from 'theme/components/link'
import { Modal } from 'theme/components/modal'
import { Radio } from 'theme/components/radio'
import { Select } from 'theme/components/select'
import { Table } from 'theme/components/table'
import { Tabs } from 'theme/components/tabs'
import { Tag } from 'theme/components/tag'
import { Textarea } from 'theme/components/textarea'

import { breakpoints } from './foundations/breakpoints'
import { colors } from './foundations/colors'
import { fontSizes } from './foundations/font-sizes'
import { sizes } from './foundations/sizes'
import { global } from './global'

const overrides: Partial<ChakraTheme> = {
  config: {
    cssVarPrefix: 'nm',
  },
  styles: {
    global,
  },
  breakpoints,
  colors,
  sizes,
  fonts: {
    heading: `Proxima Nova, Adjusted Arial Fallback, ${base.fonts?.heading}`,
    body: `Proxima Nova, Adjusted Arial Fallback, ${base.fonts?.body}`,
  },
  fontSizes,
  components: {
    Alert,
    Button,
    Checkbox,
    CloseButton,
    Drawer,
    Divider,
    Form,
    FormError,
    FormInput,
    Heading,
    Link,
    Modal,
    Select,
    Table,
    Tabs,
    Tag,
    Textarea,
    Radio,
  },
}

export default extendTheme(overrides)
