import type { BusinessUnit } from '@commercetools/platform-sdk'

/**
 * Checks if the business unit aka company has access to the portal
 */
export function hasBusinessUnitAccessToPortal(businessUnit: DeepPartial<BusinessUnit> | undefined | null): boolean {
  const fields = businessUnit?.custom?.fields

  return fields
    ? fields.ctAllowPortalAccess ??
        /**
         * Backward compatibility
         * `??` - We use the old field only when the new one is not present
         */
        fields.allowPortalAccess
    : false
}

/**
 * Checks if the customer has access to the portal
 */
export function hasCustomerAccessToPortal(
  businessUnits: ReadonlyArray<DeepPartial<BusinessUnit> | undefined | null> | undefined | null,
): boolean {
  return !!businessUnits?.filter(Boolean)?.some(hasBusinessUnitAccessToPortal)
}
