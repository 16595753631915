import {
  Container,
  Flex,
  Grid,
  HStack,
  SystemStyleInterpolation,
  SystemStyleObject,
  useDisclosure,
} from '@chakra-ui/react'
import { FunctionComponent, memo } from 'react'

import Logo from 'components/modules/Header/Logo'
import MobileMenu from 'components/modules/Header/MobileMenu'
import Navigation from 'components/modules/Header/Navigation'
import ProfileButton from 'components/modules/Header/ProfileButton'
import Sites from 'components/modules/Header/Sites'

export const HEADER_HEIGHT = {
  base: '48px' /* 48px */,
  md: '154px' /* 80px top header + 74px navigation menu */,
} as const

// Active site link is underlined, but on hover the active underline should be hidden
const sx = {
  'ul:hover a:not(:hover)': {
    _before: {
      lg: {
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
    },
  },
} as const satisfies SystemStyleObject

const styles = {
  header: {
    w: 'full',
    alignItems: 'center',
    h: HEADER_HEIGHT,
    backgroundColor: { base: 'gray333', md: 'primary.white' },
    zIndex: 100,
    boxShadow: { base: '0px 4px 8px 0px rgba(0, 0, 0, 0.15);', lg: 'none' },
    direction: 'column',
    pos: { base: 'sticky', md: 'inherit' },
    top: 0,
  },
  menu: {
    alignItems: 'center',
    gridTemplateColumns: {
      base: 'auto 1fr',
      md: '1fr auto 1fr',
    },
    gridAutoFlow: 'dense',
  },
  logo: {
    gridColumn: { base: 2, md: 1 },
    ml: { base: 2, md: 6, lg: 0 },
  },
  buttons: {
    justifyContent: { md: 'flex-end' },
    gridColumn: { base: 1, md: 3 },
  },
  headerWrapper: {
    h: HEADER_HEIGHT,
    py: { base: 0, md: 4 },
    pl: { base: 0, lg: 10 },
    pr: { base: 0, lg: 6 },
    maxW: 'desktop',
  },
  navigationWrapper: {
    display: { base: 'none', md: 'flex' },
    minH: '74px',
    backgroundColor: 'gray333',
    maxW: 'full',
    pt: 8,
    pb: 7,
    px: { base: 6, lg: 10 },
  },
} as const satisfies SystemStyleInterpolation

const Header: FunctionComponent = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <Flex as="header" {...styles.header}>
      <Container {...styles.headerWrapper}>
        <Grid {...styles.menu} sx={sx}>
          <Flex {...styles.logo}>{!isOpen && <Logo adjustMobile />}</Flex>
          <Sites />
          <HStack spacing={2} {...styles.buttons}>
            <ProfileButton />
            <MobileMenu isOpen={isOpen} onClose={onClose} onToggle={onToggle} />
          </HStack>
        </Grid>
      </Container>
      <Container {...styles.navigationWrapper}>
        <Navigation />
      </Container>
    </Flex>
  )
}

export default memo(Header)
