import type { ErrorObject } from '@commercetools/platform-sdk'
import type { CustomerPasswordError } from '@commercetools/sdk-auth'

/**
 * Commercetools errors that should not be retried by the useQuery hook.
 * @link https://docs.commercetools.com/api/errors
 */
const ONE_RETRY_ERRORS_COMMERCETOOLS = [
  'invalid_customer_account_credentials',
  'no_access',
  'email_not_verified',
  'invalid_token',
  'invalid_client',
  'access_denied',
  'email_is_required',
  'insufficient_scope',
  'ConcurrentModification',
  'InvalidCurrentPassword',
  'InvalidOperation',
  'InvalidJsonInput',
  'InvalidField',
  'InternalConstraintViolated',
  'ObjectNotFound',
  'ResourceNotFound',
  'RequiredField',
  'account_disabled',
  'InvalidInput',
  'MatchingPriceNotFound',
  'MissingTaxRateForCountry',
  'CountryNotConfiguredInStore',
  'OutOfStock',
  'SearchExecutionFailure',
  'InvalidItemShippingDetails',
  'ShippingMethodDoesNotMatchCart',
  'DiscountCodeNonApplicable',
]

export function isCommercetoolsSDKError(
  error: unknown,
): error is (CustomerPasswordError | ErrorObject) & { name?: string; message: string } {
  return typeof error === 'object' && !!error && 'statusCode' in error
}

function isCommercetoolsError(error: unknown): error is ErrorObject {
  return (
    typeof error === 'object' &&
    !!error &&
    'errors' in error &&
    Array.isArray(error.errors) &&
    !!error.errors[0] &&
    'message' in error
  )
}

function getErrorCode(error: unknown): string {
  if (typeof error === 'object' && error && 'body' in error) {
    if (isCommercetoolsError(error.body)) {
      return error.body.errors[0].code
    }
  }
  if (isCommercetoolsError(error)) {
    return error.errors[0].code
  }
  if (isCommercetoolsSDKError(error)) {
    const errorCode = [error.error, error.name, error.message].filter((text) =>
      ONE_RETRY_ERRORS_COMMERCETOOLS.includes(text),
    )[0]
    return errorCode || ''
  }
  return ''
}

function isCommercetoolsOneTryError(error: unknown): boolean {
  const code = getErrorCode(error)
  return ONE_RETRY_ERRORS_COMMERCETOOLS.includes(code)
}

export default isCommercetoolsOneTryError

/**
 * Type guard to check if the given data contains a ConcurrentModification error
 */
export function getConcurrentModificationError(response: unknown): number | null {
  try {
    const errors =
      response && typeof response === 'object' && 'errors' in response && Array.isArray(response.errors)
        ? response.errors
        : []
    const error = errors.find((error) => error?.code === 'ConcurrentModification')
    const currentVersion = error?.currentVersion ?? null
    return currentVersion ? currentVersion : null
  } catch (error) {
    return null
  }
}
