import isCommercetoolsOneTryError from 'commercetools/utils/isCommercetoolsOneTryError'
import { ONE_RETRY_ERRORS_DATABASE } from 'database/config'
import isDatabaseError from 'database/utils/isDatabaseError'

const DEFAULT_RETRY_COUNT = 3

function shouldRetry(failureCount: number, error: unknown) {
  const hasRetries = failureCount < DEFAULT_RETRY_COUNT
  if (hasRetries) {
    if (isDatabaseError(error) && ONE_RETRY_ERRORS_DATABASE.includes(error.code)) {
      return false
    }

    if (isCommercetoolsOneTryError(error)) {
      return false
    }
  }
  return hasRetries
}

export default shouldRetry
