import type { ClientResponse, Customer } from '@commercetools/platform-sdk'

import { CT_MY_CLIENT } from 'commercetools/client/user'
import { getConcurrentModificationError } from 'commercetools/utils/isCommercetoolsOneTryError'

interface Variables {
  customerVersion: number
  businessUnitId: string
}

/**
 * Sets a business unit in a customer's custom fields.
 * @link https://docs.commercetools.com/api/projects/me-profile#set-customfield
 */
async function setCustomerBusinessUnit({
  businessUnitId,
  customerVersion,
}: Variables): Promise<ClientResponse<Customer>> {
  const setCustomFieldAction = {
    action: 'setCustomField' as const,
    name: 'ctSelectedBusinessUnit',
    value: { typeId: 'business-unit' as const, id: businessUnitId },
  }

  const executeRequest = async (version: number): Promise<ClientResponse<Customer>> => {
    return await CT_MY_CLIENT.me()
      .post({
        body: {
          version,
          actions: [setCustomFieldAction],
        },
      })
      .execute()
      .catch((error) => {
        const currentVersion = getConcurrentModificationError(error.body)
        if (currentVersion !== null) {
          return executeRequest(currentVersion)
        }
        throw error
      })
  }

  return executeRequest(customerVersion)
}

export default setCustomerBusinessUnit
