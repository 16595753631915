import getCustomerFullName from 'commercetools/utils/getCustomerFullName'
import type { BusinessUnit } from 'utils/Providers/BusinessUnit/context'

function getCompanyName(businessUnit: Partial<BusinessUnit> | undefined | null): string {
  if (!businessUnit) {
    return ''
  }
  return businessUnit.name || getCustomerFullName(businessUnit)
}

export default getCompanyName
