import type { ThemeComponents } from '@chakra-ui/react'

/**
 * Override modal component configuration.
 * @link https://chakra-ui.com/docs/components/modal
 * @default `src\node_modules\@chakra-ui\theme\dist\components\modal.js`
 */
export const Modal = {
  baseStyle: {
    dialog: {
      p: { base: 4, lg: 6 },
      bg: 'primary.white',
      borderRadius: '0',
    },
    header: {
      px: 0,
      paddingBlockStart: 0,
      paddingBlockEnd: '5',
      fontSize: 'lg',
      borderStyle: 'solid',
      borderBottomWidth: '.25rem',
      borderBottomColor: 'gray.100',
      marginBlockEnd: '4',
    },
    body: {
      px: 0,
    },
    closeButton: {
      top: 4,
      right: { base: 4, lg: 4 },
    },
    footer: {
      borderStyle: 'solid',
      borderTopWidth: '.25rem',
      borderTopColor: 'gray.100',
      paddingBlockStart: '5',
      marginBlockStart: '4',
      px: 0,
      paddingBlockEnd: 0,
      justifyContent: 'space-between',

      '& button[type="submit"]': {
        paddingInline: 8,
      },
    },
  },
} satisfies ThemeComponents['Modal']
