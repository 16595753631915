import type { SystemStyleInterpolation } from '@chakra-ui/react'

export const global: Partial<SystemStyleInterpolation> = {
  html: {
    height: '100%',
    // Modal hides scrollbar, so we need to update gutter accordingly
    '&:not(:has(.chakra-portal [aria-modal="true"]))': {
      scrollbarGutter: 'stable',
    },
  },
  body: {
    display: 'grid',
    gridTemplateRows: '1fr',
    backgroundColor: 'primary.white',
    color: 'primary.black',
    fontSize: 'md',
    scrollbarWidth: 'auto',
    printColorAdjust: 'exact',
  },
  '#__next': {
    minWidth: 0,
  },
  a: {
    cursor: 'pointer',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'visible',
    verticalAlign: 'middle',
  },
  h1: {
    fontSize: 'xl',
    lineHeight: 2,
  },
  h2: {
    fontSize: 'lg',
    lineHeight: 1.375,
  },
  h3: {
    fontSize: 'md',
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 'md',
    lineHeight: 1.5,
  },
  'a[aria-current]': {
    fontWeight: 'bold',
  },
  '*': {
    scrollbarColor: '#ccc',
  },
  '*::-webkit-scrollbar': {
    width: '0.375rem',
    height: '0.375rem',
  },
  'body::-webkit-scrollbar': {
    width: '0.75rem',
    height: '0.75rem',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '1rem',
    transition: 'background-color 0.2s ease',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#999',
  },
  // https://syntackle.com/blog/changes-to-scrollbar-styling-in-chrome-121/
  '@supports not selector(::-webkit-scrollbar)': {
    '*': {
      scrollbarWidth: 'thin',
    },
  },
  '.grecaptcha-badge ': {
    visibility: 'hidden',
  },
  '@font-face': [
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Regular')`,
        `local('ProximaNova-Regular')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaRegular.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaRegular.woff') format('woff')`,
        `url('/fonts/ProximaNovaRegular.otf') format('opentype')`,
      ].join(),
    },
    {
      fontFamily: `'Proxima Nova'`,
      fontStyle: 'normal',
      fontWeight: 700,
      fontDisplay: 'swap',
      src: [
        `local('Proxima Nova Bold')`,
        `local('ProximaNova-Bold')`,
        `local('Proxima Nova')`,
        `url('/fonts/ProximaNovaBold.woff2') format('woff2')`,
        `url('/fonts/ProximaNovaBold.woff') format('woff')`,
        `url('/fonts/ProximaNovaBold.otf') format('opentype')`,
      ].join(),
    },
    // Generated with `pixel-point/fontpie`
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: 'local(Arial)',
      fontStyle: 'normal',
      fontWeight: 400,
      sizeAdjust: '99%',
      ascentOverride: '99%',
      descentOverride: '26%',
      lineGapOverride: '0%',
    },
    {
      fontFamily: 'Adjusted Arial Fallback',
      src: "local('Arial Bold')",
      fontStyle: 'normal',
      fontWeight: 700,
      ascentOverride: '97.08%',
      descentOverride: '31.44%',
      lineGapOverride: '0.00%',
      sizeAdjust: '94.77%',
    },
  ],
} as const
