import type { ThemeComponents } from '@chakra-ui/react'

export const Select = {
  baseStyle: {
    icon: {
      fontSize: 'lg',
      color: 'primary.red',
      zIndex: 1,
    },
    field: {
      fontWeight: 700,
    },
  },
} as const satisfies ThemeComponents['Select']
